import { VehicleTypeCapacity, VehicleTypeKey, VehicleTypeLabel } from './enum'

/**
 * Converts a provided `VehicleTypeKey` to its corresponding `VehicleTypeLabel`.
 * If the key does not match any predefined mapping, an error is thrown.
 *
 * @param {VehicleTypeKey} key - The vehicle type key to convert.
 * @returns {VehicleTypeLabel} The corresponding vehicle type label.
 * @throws {Error} If the key does not have a corresponding label in the map.
 */
export const vehicleTypeKeyToLabel = (key: VehicleTypeKey): VehicleTypeLabel => {
  const map: { [key in VehicleTypeKey]: VehicleTypeLabel } = {
    [VehicleTypeKey.CharterBus]: VehicleTypeLabel.CharterBus,
    [VehicleTypeKey.MiniBus]: VehicleTypeLabel.MiniBus,
    [VehicleTypeKey.Sprinter]: VehicleTypeLabel.Sprinter,
    [VehicleTypeKey.PartyBus]: VehicleTypeLabel.PartyBus,
    [VehicleTypeKey.Sedan]: VehicleTypeLabel.Sedan,
    [VehicleTypeKey.SUV]: VehicleTypeLabel.SUV,
    [VehicleTypeKey.Limousine]: VehicleTypeLabel.Limousine,
    [VehicleTypeKey.Trolley]: VehicleTypeLabel.Trolley,
    [VehicleTypeKey.SchoolBus]: VehicleTypeLabel.SchoolBus,
    [VehicleTypeKey.Van]: VehicleTypeLabel.Van,
  }

  if (!(key in map)) {
    throw new Error(`Invalid VehicleTypeKey: ${key}`)
  }

  return map[key]
}

/**
 * Converts a provided `VehicleTypeKey` to its corresponding `VehicleTypeCapacity`.
 * If the key does not match any predefined mapping, an error is thrown.
 *
 * @param {VehicleTypeKey} key - The vehicle type key to convert.
 * @returns {VehicleTypeCapacity} The corresponding vehicle type capacity.
 * @throws {Error} If the key does not have a corresponding capacity in the map.
 */
export const vehicleTypeKeyToCapacity = (key: VehicleTypeKey): VehicleTypeCapacity => {
  const map = {
    [VehicleTypeKey.CharterBus]: VehicleTypeCapacity.CharterBus,
    [VehicleTypeKey.MiniBus]: VehicleTypeCapacity.MiniBus,
    [VehicleTypeKey.Sprinter]: VehicleTypeCapacity.Sprinter,
  }

  if (!(key in map)) {
    throw new Error(`Invalid VehicleTypeKey: ${key}`)
  }

  return map[key]
}
