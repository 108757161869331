
import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import ShareQuoteDialog from '@/components/ShareQuoteDialog.vue'
import { CustomerAccount, Quote } from '../models/dto'
import auth from '../store/modules/auth'
import support from '../store/modules/support'
import CustomerAccountDefaultValueSet from '@/models/dto/CustomerAccountDefaultValueSet'

@Component({ components: { ShareQuoteDialog } })
export default class CheckoutHeader extends Vue {
  @Inject({ from: 'isInGuestCheckout', default: false })
  readonly isInGuestCheckout: boolean

  @Prop({ type: Object, required: true }) quote!: Quote
  @Prop({ type: Boolean, default: false }) isSingleColumn!: boolean
  @Prop({ type: Boolean, default: false }) fullWidth!: boolean
  @Prop({ type: Object, default: () => {} }) customerAccount!: CustomerAccount
  @Prop({ type: Object, default: () => {} })
  effectiveDefaultValueSet!: CustomerAccountDefaultValueSet

  get quoteId(): number {
    return this.quote?.quoteId
  }

  get quoteCustomerAccountMatchesLoggedInCustomerAccount() {
    return (
      this.quote?.trips[0]?.customer?.customerAccountId &&
      this.quote?.trips[0]?.customer.customerAccountId ===
        this.customerAccount?.customerAccountId
    )
  }

  get headerText(): string {
    const { name } = this.customerAccount || {}
    if (
      this.quoteCustomerAccountMatchesLoggedInCustomerAccount &&
      this.customerAccount.nameApprovedForUserInterface &&
      name
    ) {
      return `Your ${name} trip is ready to book!`
    }
    return 'Your trip is ready to book!'
  }

  get showShareQuoteDialog(): boolean {
    if (!this.quote) {
      return false
    }
    return this.isUserQuoteCustomer || this.isUserInSameOrganization
  }

  get isUserQuoteCustomer(): boolean {
    const quoteCustomerId = this.quote?.trips?.[0]?.customerId
    return quoteCustomerId === auth.user?.userId
  }

  get isUserInSameOrganization(): boolean {
    const quoteCustomerCustomerAccountId = this.quote?.trips?.[0]?.customer
      ?.customerAccountId
    if (!quoteCustomerCustomerAccountId) {
      return false
    }
    return (
      quoteCustomerCustomerAccountId ===
        auth.customerAccount.customerAccountId ||
      auth.childCustomerAccountIds.includes(quoteCustomerCustomerAccountId)
    )
  }

  openSupport(): void {
    support.open({
      reservationId: null,
      quoteId: this.quoteId,
    })
  }
}
