import { render, staticRenderFns } from "./OrganizationTeams.vue?vue&type=template&id=8bf53180&scoped=true"
import script from "./OrganizationTeams.vue?vue&type=script&lang=ts"
export * from "./OrganizationTeams.vue?vue&type=script&lang=ts"
import style0 from "./OrganizationTeams.vue?vue&type=style&index=0&id=8bf53180&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bf53180",
  null
  
)

export default component.exports