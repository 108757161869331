import { Quote } from '@/models/dto'
import { TripTypeKey } from './enum'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { QuoteDetailV2 } from '../models/dto/QuoteDetailV2'
dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * Checks if sales will call for the given quote.
 *
 * @param quote - The quote to check.
 * @returns `true` if sales will call for the given quote, `false` otherwise.
 */
export const willSalesCall = (quote: QuoteDetailV2): boolean => {
  if (!quote) {
    return false
  }
  const noBids = !quote?.bids?.length
  const isTripLargeEventOrLongTermShuttle = quote?.trips.some((trip) =>
    [
      TripTypeKey.LargeEvent as string,
      TripTypeKey.LongTermShuttle as string,
    ].includes(trip?.tripType?.key)
  )
  return noBids && isTripLargeEventOrLongTermShuttle
}

/**
 * Checks if the user should call in for the given quote.
 *
 * @param quote - The quote to check.
 * @returns `true` if the user should call in for the given quote, `false` otherwise.
 *
 */
export const shouldUserCallIn = (quote: QuoteDetailV2): boolean => {
  if (!quote) {
    return false
  }

  if (doesQuoteHaveAPrice(quote)) {
    return false
  }

  const stops = quote.trips?.[0]?.stops
  const firstPickup = stops.find((stop) => stop.orderIndex === 0)
  if (!firstPickup) {
    return false
  }

  const createdOn = dayjs(quote.createdOn)
  const pickupTime = dayjs(firstPickup.pickupDatetime)

  const wasCreatedWithin24HoursOfPickup =
    pickupTime.diff(createdOn, 'hours') < 24
  return wasCreatedWithin24HoursOfPickup
}

/**
 * Checks if the given quote has a price.
 * @param quote - The quote to check.
 * @returns `true` if the quote has a price, `false` otherwise.
 */
export const doesQuoteHaveAPrice = (quote: QuoteDetailV2): boolean => {
  if (!quote) {
    return false
  }
  return !!quote?.bids?.[0] && !!quote?.bids?.[0].totalAmount
}

/**
 * Checks if the given expiration datetime has passed.
 *
 * @param expirationDatetime - The expiration datetime to check.
 * @returns `true` if the expiration datetime has passed, `false` otherwise.
 */
export const isExpired = (expirationDatetime: string): boolean => {
  if (!expirationDatetime) {
    return true
  }
  const now = new Date()
  const thenDate = new Date(`${expirationDatetime.split('+')[0]}Z`)
  return now.getTime() - thenDate.getTime() > 0
}

/**
 * Checks if the given quote is active.
 *
 * A quote is considered active if its expiration date has not passed and it has not been converted to a reservation.
 *
 * @param quote - The quote to check.
 * @returns `true` if the quote is active, `false` otherwise.
 */
export const isQuoteDetailActive = (quote: QuoteDetailV2): boolean => {
  if (!quote) {
    return false
  }
  return (
    !isExpired(quote.expirationDate) && !quote.isConverted && quote.isActive
  )
}

/**
 * Checks if the given quote is active.
 *
 * A quote is considered active if its expiration date has not passed and it has not been converted to a reservation.
 *
 * @param quote - The quote to check.
 * @returns `true` if the quote is active, `false` otherwise.
 */
export const isQuoteActive = (quote: Quote): boolean => {
  if (!quote) {
    return false
  }
  return !isExpired(quote.expirationDate) && !quote.isConverted
}

/**
 *  Checks if the given quote is a self serve quote and is created within 24 hours of the pickup time, is a large event, or is a long term shuttle.
 *
 * @param quote - The quote to check.
 * @returns `true` if the quote is a self serve quote and is created within 24 hours of the pickup time, is a large event, or is a long term shuttle, `false` otherwise.
 */
export const isQuoteSelfServeAndNeedsManualAttention = (
  quote: QuoteDetailV2
): boolean => {
  if (!quote) {
    return false
  }

  if (!quote.isSelfServe) {
    return false
  }

  return (
    quote.isWithin24Hours ||
    quote.isLargeEvent ||
    quote.isLongTermShuttle ||
    quote.isBillAfterServicesAndWithin4Days ||
    (quote.pricingMethod === 'bids' && !quote.isActive)
  )
}
