var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{class:{
    'margin-y-4': _vm.$vuetify.breakpoint.lgAndUp || !_vm.inResDetail,
  }},[_c('v-card-text',{class:{
      'padding-y-5 padding-x-6': _vm.$vuetify.breakpoint.lgAndUp && _vm.inResDetail,
      'padding-y-5': !_vm.inResDetail,
      'padding-x-0 padding-y-0': _vm.$vuetify.breakpoint.mdAndDown && _vm.inResDetail,
    }},[_c('v-row',[_c('v-col',{staticClass:"padding-b-2",attrs:{"cols":"12"}},[_c('h2',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])],1),(_vm.charterBus && _vm.areAddedAmenities)?_c('p',{staticClass:"margin-y-3"},[_vm._v(" Added extras ")]):_vm._e(),_c('v-row',_vm._l((_vm.addedAmenities),function(amenity,amenityIndex){return _c('v-col',{key:`${amenity}-amenity-${amenityIndex}`,staticClass:"padding-y-2",attrs:{"cols":"6","sm":"6","md":"4","lg":"6"}},[_c('TripAmenityCardItem',{attrs:{"amenity":amenity}})],1)}),1),(_vm.charterBus && _vm.formattedIncludedAmenities)?_c('div',{staticClass:"margin-t-6"},[_c('p',{staticClass:"font-14"},[_vm._v(" "+_vm._s(_vm.formattedIncludedAmenities)+" ")])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }