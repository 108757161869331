
import { Vue, Component, Prop } from 'vue-property-decorator'
import { OperatorDetailCompany } from '../models/dto'
import { alwaysShowTenthsPlace } from '../utils/string'
import CertifiedPartnerBadge from './CertifiedPartnerBadge.vue'
import CStarRating from '@/components/CStarRating.vue'
import { certifiedPartnerModalDetail } from '@/utils/infoModals'
import InfoModal from '@/components/InfoModal.vue'

@Component({
  components: { CertifiedPartnerBadge, InfoModal, CStarRating }
})
export default class ProviderInfoHeader extends Vue {
  @Prop({ type: Object, required: true })
  readonly company!: OperatorDetailCompany

  certifiedPartnerModalDetail = certifiedPartnerModalDetail
  isOpen = false

  get formattedRating(): string {
    return alwaysShowTenthsPlace(this.company.rating)
  }

  get website(): string {
    return this.company.customerFacingUrl
  }

  getUrl(url): string {
    if (!url.match(/^https?:\/\//i)) {
      url = `http://${url}`
    }
    return url
  }
}
