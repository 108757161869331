
import { Vue, Component, Prop, Watch, ProvideReactive } from 'vue-property-decorator'
import { ReservationDetail } from '@/models/dto'
import ShareLiveTrackingDialog from '@/components/ShareLiveTrackingDialog.vue'
import { ReservationStatusKey, SplitFeatureFlag } from '@/utils/enum'
import { EventBus } from '@/utils/eventBus'
import split from '@/store/modules/split'
import auth from '@/store/modules/auth'

@Component({
  components: {
    ShareLiveTrackingDialog
  },
})
export default class TripLiveTracking extends Vue {
  @Prop({ type: Object, required: true }) readonly reservation: ReservationDetail

  shareLiveTrackingDialogIsOpen = false
  isItineraryModificationWizardEnabled = false

  split = split
  @Watch('split.isReady', { immediate: true })
  async onSplitReady(isReady: boolean): Promise<void> {
    if (!isReady) {
      return
    }

    this.isItineraryModificationWizardEnabled = await split.isFeatureEnabled(
      SplitFeatureFlag.ItineraryModificationWizard
    )
  }

  mounted(): void {
    EventBus.$on('open-share-tracking', () => {
      this.shareLiveTrackingDialogIsOpen = true
    })
  }

  get isLiveTracking(): boolean {
    return (
      this.reservation?.hasLiveTrackingData &&
      this.reservation.reservationStatus === ReservationStatusKey.Started
    )
  }

  get willHaveLiveTracking(): boolean {
    return this.reservation?.willHaveLiveTracking
  }

  get showLiveTracking(): boolean {
    if (
      this.reservation.reservationStatus === ReservationStatusKey.Finished ||
      this.reservation.reservationStatus === ReservationStatusKey.Cancelled
    ) {
      return false
    }
    if (this.isLiveTracking || this.willHaveLiveTracking) {
      return true
    }
    return false
  }

  get isUserBookingContact(): boolean {
    return auth.userId === this.reservation.customer?.customerId
  }

  get isReservationUpcomingOrOnHold(): boolean {
    return (
      this.reservation.reservationStatus === ReservationStatusKey.Upcoming ||
      this.reservation.reservationStatus === ReservationStatusKey.OnHold
    )
  }

  get showTripModification(): boolean {
    return this.isItineraryModificationWizardEnabled
      && this.reservation?.allowSelfServeModification
      && !this.reservation?.hasPendingModificationRequest
  }

  get liveTrackingAvailableCopy(): string {
    if (this.isLiveTracking) {
      return `Live tracking available`
    }
    return `Live tracking available on ${this.pickupDate}`
  }

  get pickupDate(): string {
    return this.$dayjs(this.reservation?.pickupDate)
      .tz(this.reservation?.stops[0].address.timeZone)
      .format('MM/DD')
  }

  onViewMapClick() {
    const path = {
      name: 'public-tracking',
      params: {
        hash: this.reservation.hash,
      },
    }
    const routeData = this.$router.resolve(path)
    window.open(routeData.href, '_blank')
  }

  openTripModificationStartDialog(): void {
    EventBus.$emit('open-trip-modification-dialog')
  }
}
