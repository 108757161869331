
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DummyCompanyReview, OperatorDetailCompany } from '../models/dto'
import { DateTime } from 'luxon'
import CStarRating from '@/components/CStarRating.vue'

@Component({ components: { CStarRating } })
export default class ProviderInfoReviews extends Vue {
  @Prop({ type: Object, required: true })
  readonly company!: OperatorDetailCompany

  get reviews(): DummyCompanyReview[] {
    return this.company?.companyReviews2 || []
  }

  formattedReviewPickupTime(pickupDatetime): string {
    return DateTime.fromISO(pickupDatetime).toFormat('LLLL yyyy')
  }
}
