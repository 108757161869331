
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Note, Stop } from '../models/dto'
import TripTabs from '@/components/TripTabs.vue'
import TripMap from '@/components/TripMap.vue'
import TripMetadata from '@/components/TripMetadata.vue'
import TripNotes from '@/components/TripNotes.vue'
import TripItinerary from '@/components/TripItinerary.vue'
import QuoteCardMobileInformation from '@/components/QuoteCardMobileInformation.vue'
import { NoteTypeId } from '../utils/enum'
import { QuoteDetailV2, QuoteDetailV2Trip } from '../models/dto/QuoteDetailV2'

@Component({
  components: {
    TripTabs,
    TripMap,
    TripMetadata,
    TripNotes,
    TripItinerary,
    QuoteCardMobileInformation,
  },
})
export default class QuoteCard extends Vue {
  @Prop({ type: Object, required: true }) quote!: QuoteDetailV2
  @Prop({ type: Boolean, required: false, default: false }) hideMap!: boolean
  @Prop({ type: Boolean, required: false, default: false })
  hideItinerary!: boolean

  currentTripIndex = 0

  get trips(): QuoteDetailV2Trip[] {
    return this.quote?.trips
  }

  get currentTrip(): QuoteDetailV2Trip {
    return this.trips?.[this.currentTripIndex] || null
  }

  get stops(): Stop[] {
    return this.currentTrip?.stops || []
  }

  get showTabs(): boolean {
    return this.trips?.length > 1
  }

  get tripNotes(): Note[] {
    return this.currentTrip?.tripNotes?.filter(
      (note) => note.noteType === NoteTypeId.Customer
    )
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }
}
