import Vue from 'vue'
import axios from 'axios'
import { baseUrl } from '@/utils/env'
import modules from '@/store/modules'

axios.defaults.baseURL = baseUrl() || ''
axios.defaults.headers.common['Accept-Language'] =
  JSON.parse(localStorage.getItem('locale')) || 'en'
axios.defaults.headers.get.Pragma = 'no-cache'
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store'
axios.defaults.headers.common['Application-Origin'] = 'charterup'

axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    // If request is different than any of the URLS in urlsExcludedForBearerHeader
    // then send Authorization header with token from localstorage
    const urlsExcludedForBearerHeader = [
      '/login',
      '/forgot',
      '/register',
      '/reset',
      `${window.location.origin}/version.json`,
    ]
    const token = JSON.parse(localStorage.getItem('token'))
    if (urlsExcludedForBearerHeader.indexOf(config.url) === -1 && token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) =>
    // Do something with request error
    Promise.reject(error)
)

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    // Checks if app is being used in mobile

    if (
      response.config.url !== `${baseUrl()}/token` &&
      response.config.url !== `${window.location.origin}/version.json`
    ) {
      // TODO: add this stuff back when we convert this thing to a full PWA
      // checkForUpdates()
      // checkIfTokenNeedsRefresh()
    }
    return response
  },
  (error) => {
    const isUnauthorized = error?.response?.status === 401
    if (isUnauthorized) {
      modules.auth.logout()
    }
    // Do something with response error
    return Promise.reject(error)
  }
)

Plugin.install = () => {
  Vue.axios = axios
  window.axios = axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios
      },
    },
    $axios: {
      get() {
        return axios
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin
