import { RouteConfig } from 'vue-router'
import auth from '@/store/modules/auth'
import systemParameter from '@/store/modules/systemParameter'
import nps from '@/services/nps'

export const routes: RouteConfig[] = [
  {
    path: '/',
    component: () => import('@/views/Empty.vue'),
    children: [
      {
        path: '',
        name: 'home',
        meta: {
          requiresAuth: true,
        },
        redirect: 'quotes',
        component: () => import('@/views/Home.vue'),
        children: [
          {
            path: 'quotes',
            component: () => import('@/views/Empty.vue'),
            children: [
              {
                path: '',
                name: 'quote-index',
                component: () =>
                  import(
                    /* webpackChunkName: "quote-index" */ '@/views/QuoteIndex.vue'
                  ),
              },
              {
                path: 'hash/:hash/:mode?',
                name: 'quote-detail-by-hash',
                props: (router) => ({
                  passedQuote: router.params.passedQuote,
                }),
                component: () =>
                  import(
                    /* webpackChunkName: "quote-detail" */ '@/views/QuoteDetail.vue'
                  ),
                beforeEnter: async (to, from, next) => {
                  await auth.refreshCustomerAccount()
                  next()
                },
              },
              {
                path: ':quoteId/checkout',
                name: 'checkout-single-bid',
                props: (router) => ({
                  disputedCheckout: router.params.disputedCheckout === 'true',
                }),
                component: () =>
                  import(
                    /* webpackChunkName: "checkout" */ '@/views/Checkout.vue'
                  ),
              },
              {
                path: ':id/:mode?',
                name: 'quote-detail',
                props: (router) => ({
                  passedQuote: router.params.passedQuote,
                }),
                component: () =>
                  import(
                    /* webpackChunkName: "quote-detail" */ '@/views/QuoteDetail.vue'
                  ),
                beforeEnter: async (to, from, next) => {
                  await auth.refreshCustomerAccount()
                  next()
                },
              },
              {
                path: ':quoteId/provider/:providerId',
                name: 'provider-detail',
                component: () =>
                  import(
                    /* webpackChunkName: "reservation-index" */ '@/views/Provider.vue'
                  ),
              },
              {
                path: ':quoteId/provider/:providerId/checkout',
                name: 'checkout',
                props: (router) => ({
                  disputedCheckout: router.params.disputedCheckout === 'true',
                }),
                component: () =>
                  import(
                    /* webpackChunkName: "checkout" */ '@/views/Checkout.vue'
                  ),
              },
            ],
          },
          {
            path: 'reservations',
            component: () => import('@/views/Empty.vue'),
            children: [
              {
                path: '',
                name: 'reservation-index',
                props: (router) => {
                  return { successDialog: router.params.successDialog }
                },
                component: () =>
                  import(
                    /* webpackChunkName: "reservation-index" */ '@/views/ReservationIndex.vue'
                  ),
              },
              {
                path: 'hash/:hash',
                name: 'reservation-detail-by-hash',
                component: () =>
                  import(
                    /* webpackChunkName: "reservation-detail" */ '@/views/ReservationDetail.vue'
                  ),
                beforeEnter: async (to, from, next) => {
                  await auth.refreshCustomerAccount()
                  next()
                },
              },
              {
                path: ':id',
                name: 'reservation-detail',
                props: (router) => ({
                  openTripModificationWizard: router.params.openTripModificationWizard == 'true',
                }),
                component: () =>
                  import(
                    /* webpackChunkName: "reservation-detail" */ '@/views/ReservationDetail.vue'
                  ),
                beforeEnter: async (to, from, next) => {
                  await auth.refreshCustomerAccount()
                  next()
                },
              },
              {
                path: ':id/cancel',
                name: 'reservation-cancel',
                component: () =>
                  import(
                    /* webpackChunkName: "reservation-cancel" */ '@/views/ReservationCancel.vue'
                  ),
              },
            ],
          },
          {
            path: 'provider/:providerId/:marketId',
            name: 'provider-detail-preview',
            component: () =>
              import(
                /* webpackChunkName: "reservation-index" */ '@/views/Provider.vue'
              ),
            beforeEnter: (to, from, next) => {
              const hasPermission = auth.isCMSAdmin
              if (hasPermission) {
                next()
              } else {
                next({ name: 'home' })
              }
            },
          },
          {
            path: '/organization',
            name: 'organization',
            redirect: { name: 'organization-account' },
            component: () => import('@/views/Empty.vue'),
            beforeEnter: (to, from, next) => {
              const hasPermission = auth.isCustomerAccountAdmin
              if (hasPermission) {
                next()
              } else {
                next({ name: 'home' })
              }
            },
            children: [
              {
                path: 'account',
                name: 'organization-account',
                component: () => import('@/views/Organization.vue'),
              },
              {
                path: 'riders',
                name: 'organization-riders',
                component: () => import('@/views/Organization.vue'),
              },
              {
                path: 'shared-contacts',
                name: 'shared-contacts',
                component: () => import('@/views/Organization.vue'),
              },
              {
                path: 'rider-data',
                name: 'organization-rider-data',
                component: () => import('@/views/Organization.vue'),
              },
              {
                path: 'teams',
                name: 'organization-teams',
                component: () => import('@/views/Organization.vue'),
              },
            ],
          },
          {
            path: '/finance',
            component: () => import('@/views/Empty.vue'),
            beforeEnter: (to, from, next) => {
              const hasPermission = auth.isFinanceAdmin
              if (hasPermission) {
                next()
              } else {
                next({ name: 'home' })
              }
            },
            children: [
              {
                path: '',
                name: 'finance',
                props: { mode: 'charge-dispute' },
                component: () => import('@/views/Finance.vue'),
              },
              {
                path: 'charge-dispute',
                name: 'charge-dispute',
                props: { mode: 'charge-dispute' },
                component: () => import('@/views/Finance.vue'),
              },
            ],
          },
          {
            path: '/profile',
            component: () => import('@/views/Empty.vue'),
            children: [
              {
                path: '',
                name: 'profile',
                props: { mode: 'profile' },
                component: () => import('@/views/Profile.vue'),
              },
              {
                path: 'password',
                name: 'password',
                props: { mode: 'password' },
                component: () => import('@/views/Profile.vue'),
              },
              {
                path: 'billing',
                name: 'billing',
                props: { mode: 'billing' },
                component: () => import('@/views/Profile.vue'),
              },
              {
                path: 'notifications',
                name: 'notifications',
                props: { mode: 'notifications' },
                component: () => import('@/views/Profile.vue'),
              },
            ],
          },
          {
            path: '/livetracking/:reservationId?',
            name: 'live-tracking',
            props: { showTheAppBar: false },
            component: () =>
              import(
                /* webpackChunkName: "livetracking" */ '@/views/LiveTracking.vue'
              ),
          },
          {
            path: '/enterprise/tracking',
            name: 'enterprise-tracking',
            props: { mode: 'customer-account' },
            component: () =>
              import(
                /* webpackChunkName: "enterprisetracking" */ '@/views/EnterpriseTracking.vue'
              ),
            beforeEnter: (to, from, next) => {
              const hasPermission =
                auth.isEnterpriseClient || auth.isEnterpriseAdmin
              if (hasPermission) {
                next()
              } else {
                next({ name: 'home' })
              }
            },
          },
        ],
      },
      {
        path: '',
        name: 'guest',
        component: () => import('@/views/Guest.vue'),
        props: { showDefaultAppBar: false },
        children: [
          {
            path: '/enterprise/tracking/:hash',
            name: 'enterprise-tracking.public',
            props: { mode: 'hash' },
            component: () =>
              import(
                /* webpackChunkName: "publicenterprisetracking" */ '@/views/EnterpriseTracking.vue'
              ),
          },
          {
            path: '/livetracking/public/:hash',
            name: 'public-tracking',
            props: { showTheAppBar: true },
            component: () =>
              import(
                /* webpackChunkName: "livetracking" */ '@/views/LiveTracking.vue'
              ),
          },
        ],
      },
      {
        path: '',
        name: 'guest',
        component: () => import('@/views/Guest.vue'),
        props: { showDefaultAppBar: true },
        children: [
          {
            path: 'nps',
            component: () => import('@/views/Empty.vue'),
            children: [
              {
                path: '/nps/:hash',
                name: 'nps-survey',
                component: () =>
                  import(
                    /* webpackChunkName: "nps-survey" */ '@/views/NPSSurvey.vue'
                  ),
                beforeEnter: async (to, from, next) => {
                  try {
                    const res = await nps.checkByHash(to.params.hash)
                    if (res.data.hashActive) {
                      next()
                    } else {
                      next({ name: 'nps-thank-you' })
                    }
                  } catch (error) {
                    next({ name: 'home' })
                  }
                },
              },
              {
                path: '/thank-you',
                name: 'nps-thank-you',
                component: () =>
                  import(
                    /* webpackChunkName: "nps-thank-you" */ '@/views/NPSThankYou.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: '',
        name: 'preauth',
        component: () => import('@/views/Home.vue'),
        children: [
          {
            path: '/login',
            name: 'login',
            component: () =>
              import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
          },
          {
            path: '/signup',
            name: 'signup',
            component: () =>
              import(/* webpackChunkName: "signup" */ '@/views/SignUp.vue'),
          },
          {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () =>
              import(
                /* webpackChunkName: "forgot-password" */ '@/views/ForgotPassword.vue'
              ),
          },
          {
            path: '/reset-password/:id',
            name: 'reset-password',
            component: () =>
              import(
                /* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue'
              ),
          },
          {
            path: '/selfServe/:quoteId?',
            name: 'self-serve',
            component: () =>
              import(
                /* webpackChunkName: "self-serve" */ '@/views/SelfServe.vue'
              ),
          },
          {
            path: '/cancellation/:hash',
            name: 'cancellation',
            component: () =>
              import(
                /* webpackChunkName: "cancellation" */ '@/views/Cancellation.vue'
              ),
          },
          {
            path: '/guest-checkout/:hash/confirmation',
            name: 'guest-checkout.confirmation',
            component: () =>
              import(
                /* webpackChunkName: "checkout-confirmation" */ '@/views/GuestCheckoutConfirmation.vue'
              ),
          },
          {
            path: '/guest-checkout/:hash',
            name: 'guest-checkout',
            component: () =>
              import(
                /* webpackChunkName: "guest-checkout" */ '@/views/GuestCheckout.vue'
              ),
          },
          {
            path: '/*',
            name: 'not-found',
            component: () =>
              import(
                /* webpackChunkName: "not-found" */ '@/views/NotFound.vue'
              ),
          },
        ],
      },
    ],
  },
]
