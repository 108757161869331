/**
 * Checks the URL query params for whether the emailSource is included.
 * @param path - The full URL path.
 * @returns Whether emailSource is included as a param.
 */
export const isEmailSourceInQueryParams = (
  urlParams: URLSearchParams
): boolean => {
  if (urlParams.has('fromEmail')) {
    return urlParams.get('fromEmail') === 'true'
  }
  return false
}
