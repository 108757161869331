import dayjs from 'dayjs'
import { DateTime } from 'luxon'
import { pluralize } from './string'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * Splits a datetime string into its date and time parts.
 *
 * @param datetime - The datetime string to split, in the format 'YYYY-MM-DDTHH:mm:ss'.
 * @returns An object with two properties: 'date' and 'time', representing the date and time parts of the input string.
 *          If the input is `null` or not in the correct format, the function returns `null`.
 *
 * @example
 * const datetime = '2022-12-01T12:30:00'
 * const dateTimeObj = splitDatetime(datetime)
 *
 * // dateTimeObj will be:
 * // {
 * //   date: '2022-12-01',
 * //   time: '12:30:00'
 * // }
 */
export const splitDatetime = (
  datetime: string
): { date: string; time: string } => {
  if (!datetime) {
    return null
  }
  if (datetime.includes('T')) {
    const split = datetime.split('T')
    return { date: split[0], time: split[1] }
  }
  return null
}

/**
 *
 * Converts a datetime string to a short localized datetime format, including the local timezone.
 *
 * @param datetime - The datetime string to convert, in any valid datetime format.
 * @returns A string representing the date, time, and local timezone.
 *
 * @example
 * const datetime = '2022-12-01T12:30:00'
 * const shortLocalizedDateTime = datetimeToShortLocalizedDateTime(datetime)
 *
 * //shortLocalizedDateTime will be '12/01/2022, 12:30 PM EDT' (assuming the current time in New York is 12:30 UTC)
 */
export const datetimeToShortLocalizedDateTime = (datetime: string): string => {
  if (!datetime) {
    return null
  }

  if (datetime.includes('T')) {
    return `${DateTime.fromISO(datetime).toFormat('f ZZZZ')}`
  }

  return null
}

/**
 *
 * Converts a datetime string to UNIX time in milliseconds.
 *
 * @param datetime - The datetime string to convert, in any valid datetime format.
 * @returns A number - UNIX time in milliseconds
 *
 * @example
 * const datetime = '2022-12-01T12:30:00'
 * const datetimeInMillis = datetimeToMilliseconds(datetime)
 */
export const datetimeToMilliseconds = (datetime: string): number => {
  if (isValidISODatetime(datetime)) {
    return DateTime.fromISO(datetime).toMillis()
  }

  return null
}

/**
 * Changes the timezone of a datetime string while keeping the original date and time.
 *
 * @param datetime - The datetime string to change the timezone for, in the format 'YYYY-MM-DDTHH:mm:ss'.
 * @param timeZone - The timezone to change to, using the IANA time zone database format (e.g. 'America/New_York').
 * @returns A new datetime string with the same date and time as the input, but in the specified timezone.
 *          If the input is `null` or not in the correct format, the function returns `null`.
 *
 * @example
 * const datetime = '2022-12-01T12:30:00'
 * const newDatetime = changeTimezoneKeepDatetime(datetime, 'America/New_York')
 *
 * //newDatetime will be '2022-12-01T07:30:00-05:00' (assuming the current time in New York is 12:30 UTC)
 */
export const changeTimezoneKeepDatetime = (
  datetime: string,
  timeZone: string
) => {
  if (!datetime || !timeZone || !isValidISODatetime(datetime)) {
    return null
  }
  const { date, time } = splitDatetime(datetime)
  const tempDatetime = DateTime.fromISO(`${date}T${time}`, { zone: 'utc' })
  return tempDatetime.setZone(timeZone, { keepLocalTime: true }).toISO()
}

/**
 * Converts a 12-hour time to a 24-hour time.
 *
 * @param hour - The hour to convert, in the range 1-12.
 * @param meridian - The meridian associated with the input hour ('AM' or 'PM').
 * @returns The converted hour, in the range 0-23.
 *          If the input hour is not a number or is outside the valid range, the function returns `null`.
 *
 * @example
 * const hour12 = 2
 * const meridian = 'PM'
 * const hour24 = convertHours12to24(hour12, meridian)
 *
 * // hour24 will be 14
 */
export const convertHours12to24 = (
  hour: number,
  meridian: 'AM' | 'am' | 'PM' | 'pm'
): number => {
  if (typeof hour !== 'number' || hour < 0 || hour > 12) {
    return null
  }
  if (hour === 12) {
    hour -= 12
  }
  if (meridian.toLowerCase() === 'pm') {
    hour += 12
  }
  return hour
}

/**
 * Converts a 24-hour time to a 12-hour time.
 *
 * @param hour - The hour to convert, in the range 0-23.
 * @returns The converted hour, in the range 1-12.
 *          If the input is not a number or is outside the valid range, the function returns `null`.
 *
 * @example
 * const hour24 = 14
 * const hour12 = convertHours24To12(hour24)
 *
 * // hour12 will be 2
 */
export const convertHours24To12 = (hour: number): number => {
  if (typeof hour !== 'number' || hour < 0 || hour > 23) {
    return null
  }
  if (hour >= 13) {
    hour -= 12
  }
  if (hour === 0) {
    hour += 12
  }
  return hour
}

/**
 * Determines whether a given hour is AM or PM.
 *
 * @param hour - The hour to check, in the range 0-23.
 * @returns 'AM' if the input hour is in the range 0-11, 'PM' if the input hour is in the range 12-23.
 *          If the input is not a number or is outside the valid range, the function returns `null`.
 *
 * @example
 * const hour = 14
 * const amOrPm = isAMOrPM(hour)
 *
 * // amOrPm will be 'PM'
 */
export const isAMOrPM = (hour: number): string => {
  if (typeof hour !== 'number' || hour < 0 || hour > 23) {
    return null
  }
  return hour < 12 ? 'AM' : 'PM'
}

/**
 * Rounds a given number of seconds to the nearest X minutes.
 *
 * @param secs - The number of seconds to round.
 * @param intervalInMinutes - The interval of minutes to round to (e.g. if this is 5, the function will round to the nearest 5 minutes).
 * @returns The rounded number of seconds.
 *
 * @example
 * const secs = 125
 * const intervalInMinutes = 5
 * const roundedSecs = roundSecondsToNearestXMinutes(secs, intervalInMinutes)
 *
 * // roundedSecs will be 135 (i.e. the input was rounded to the nearest 5 minutes, which is 130 seconds)
 */
export const roundSecondsToNearestXMinutes = (
  secs: number,
  intervalInMinutes: number
): number => {
  const days = Math.floor(secs / (3600 * 24))
  const hours = Math.floor((secs % (3600 * 24)) / 3600)
  const minutes = Math.floor((secs % 3600) / 60)
  const seconds = Math.floor(secs % 60) / 60
  const roundedMinutes =
    Math.ceil((minutes + seconds) / intervalInMinutes) * intervalInMinutes
  return (
    daysToSeconds(days) +
    hoursToSeconds(hours) +
    minutesToSeconds(roundedMinutes)
  )
}

/**
 * Converts a given number of days to seconds.
 *
 * @param days - The number of days to convert.
 * @returns The equivalent number of seconds.
 *
 * @example
 * const days = 1
 * const secs = daysToSeconds(days)
 *
 * // secs will be 86400
 */
export const daysToSeconds = (days: number): number => {
  return days * 24 * 3600
}

/**
 * Converts a given number of hours to seconds.
 *
 * @param hours - The number of hours to convert.
 * @returns The equivalent number of seconds.
 *
 * @example
 * const hours = 1
 * const secs = hoursToSeconds(hours)
 *
 * // secs will be 3600
 */
export const hoursToSeconds = (hours: number): number => {
  return hours * 3600
}

/**
 * Converts a given number of minutes to seconds.
 *
 * @param minutes - The number of minutes to convert.
 * @returns The equivalent number of seconds.
 *
 * @example
 * const minutes = 1
 * const secs = minutesToSeconds(minutes)
 *
 * // secs will be 60
 */
export const minutesToSeconds = (minutes: number): number => {
  return minutes * 60
}

/**
 * Converts a given number of seconds to a duration object.
 *
 * @param secs - The number of seconds to convert.
 * @returns An object with four properties: 'days', 'hours', 'minutes', and 'seconds', representing the number of days, hours, minutes, and seconds in the input duration.
 *
 * @example
 * const secs = 125
 * const duration = secondsToDuration(secs)
 *
 * // duration will be { days: 0, hours: 0, minutes: 2, seconds: 5 }
 */
export const secondsToDuration = (
  secs: number
): { days: number; hours: number; minutes: number; seconds: number } => {
  secs = Number(secs)
  const days = Math.floor(secs / (3600 * 24))
  const hours = Math.floor((secs % (3600 * 24)) / 3600)
  const minutes = Math.floor((secs % 3600) / 60)
  const seconds = Math.floor(secs % 60)

  return { days, hours, minutes, seconds }
}

/**
 * Formats a given duration object as a human-readable string.
 *
 * @param duration - The duration object to format.
 * @returns A string representing the duration in a human-readable format (e.g. "1 day 2 hours 3 minutes 4 seconds").
 *
 * @example
 * const duration = { days: 1, hours: 2, minutes: 3, seconds: 4 }
 * const formattedDuration = formatDuration(duration)
 *
 * // formattedDuration will be "1 day 2 hours 3 minutes"
 */
export const formatDuration = (duration: {
  days: number
  hours: number
  minutes: number
  seconds: number
}): string => {
  const days =
    duration.days > 0
      ? `${duration.days} ${pluralize(duration.days, 'day')} `
      : ''
  const hours =
    duration.hours > 0
      ? `${duration.hours} ${pluralize(duration.hours, 'hour')} `
      : ''
  const minutes =
    duration.minutes > 0
      ? `${duration.minutes} ${pluralize(duration.minutes, 'minute')}`
      : ''
  return `${days}${hours}${minutes}`.trim()
}

/**
 * Formats a given duration object as a minimuzed human-readable string.
 *
 * @param duration - The duration object to format.
 * @returns A string representing the duration in a human-readable format (e.g. "1 day 2 hours 3 minutes 4 seconds").
 *
 * @example
 * const duration = { days: 1, hours: 2, minutes: 3, seconds: 4 }
 * const formattedDuration = formatDuration(duration)
 *
 * // formattedDuration will be "1d 2h 3m"
 */
export const formatDurationMinimized = (duration: {
  days: number
  hours: number
  minutes: number
  seconds: number
}): string => {
  if (!duration) {
    return ''
  }

  const days = duration.days > 0 ? `${duration.days}d ` : ''
  const hours = duration.hours > 0 ? `${duration.hours}h ` : ''
  const minutes = duration.minutes > 0 ? `${duration.minutes}m` : ''
  return `${days}${hours}${minutes}`.trim()
}

/**
 * Converts a given number of seconds to a human-readable duration string.
 *
 * @param seconds - The number of seconds to convert.
 * @returns A string representing the duration in a human-readable format (e.g. "1 day 2 hours 3 minutes 4 seconds").
 *
 * @example
 * const seconds = 125
 * const formattedDuration = secondsToFormattedDuration(seconds)
 *
 * // formattedDuration will be "0 days 0 hours 2 minutes 5 seconds"
 */
export const secondsToFormattedDuration = (seconds: number) => {
  return formatDuration(secondsToDuration(seconds))
}

/**
 * Returns a human-readable string representing the amount of time that has passed since the given datetime.
 *
 * @param datetime - A datetime string in ISO format
 * @returns A string representing the amount of time that has passed since the given datetime
 */
export const timeAgo = (datetime: string): string => {
  if (!datetime || !isValidISODatetime(datetime)) {
    return null
  }
  const units = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second']

  const dateTime = DateTime.fromISO(datetime)
  const diff = dateTime.diffNow().shiftTo(...units)
  const unit = (units.find((unit) => diff.get(unit) !== 0) || 'second') as any

  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  })
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit)
}

/**
 * Returns the time until a given datetime as a human-readable string.
 *
 * @param datetime - The datetime to get the time until.
 * @param print - Whether to print the time until to the console.
 * @returns The time until the given datetime as a string, or `null` if the datetime is invalid or in the past.
 */
export const timeUntilAsString = (datetime: string, print = false): string => {
  if (!datetime || !isValidISODatetime(datetime)) {
    return null
  }

  const currentTime = dayjs()
  const timestamp = dayjs(datetime)

  const timeDiffInMilliseconds = timestamp.diff(currentTime)
  if (timeDiffInMilliseconds <= 0) {
    return null
  }

  return currentTime.to(timestamp)
}

/**
 * Validates a given string as an ISO datetime.
 * @param datetime - The datetime string to validate.
 * @returns A value indicating whether the datetime string is valid.
 */
export const isValidISODatetime = (datetime: string): boolean => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|[+-]\d{2}:\d{2})?$/.test(
    datetime
  )
}

/**
 * Returns minutes since a given datetime.
 *
 * @param datetime - A datetime string in ISO format
 * @returns A number representing the amount of time that has passed since the given datetime in minutes
 */
export const minutesSince = (datetime: string): number => {
  if (!datetime) {
    return 0
  }
  if (!isValidISODatetime(datetime)) {
    return 0
  }
  return dayjs().diff(dayjs(datetime), 'minute')
}

/**
 * Returns weeks since a given datetime.
 *
 * @param datetime - A datetime string in ISO format
 * @returns A number representing the amount of time that has passed since the given datetime in weeks
 */
export const weeksSince = (datetime: string): number => {
  if (!datetime) {
    return 0
  }
  if (!isValidISODatetime(datetime)) {
    return 0
  }
  return dayjs().diff(dayjs(datetime), 'week')
}

/**
 * Returns a human readable string giving a date and time in a given timezone.
 * @param datetime - A datetime string in ISO format
 * @param timezone - A timezone string
 * @returns A string representing the datetime in a human-readable format (e.g. "01/01/21 - 12:00 AM")
 * @example formatStopTime('2021-01-01T00:00:00.000Z', 'America/New_York')
 * // returns '01/01/21 - 12:00 AM'
 * @example formatStopTime('2021-01-01T00:00:00.000Z', 'America/Los_Angeles')
 * // returns '12/31/20 - 04:00 PM'
 */
export const formatStopTime = (datetime: string, timezone: string): string => {
  return DateTime.fromISO(datetime, { zone: timezone }).toFormat(
    'LL/dd/yy - hh:mm a'
  )
}

/**
 * Formats a given datetime string into separate time, date, and year strings,
 * adjusted to the given timezone.
 *
 * @param datetime - The datetime string to format.
 * @param timezone - The timezone for the datetime.
 * @returns An object containing formatted time, date, and year strings.
 *
 * @example
 * const formatted = formatStopTimeV2('2022-01-01T12:30:00Z', 'America/New_York');
 * // formatted will be { time: '7:30 am', date: 'Sat, Jan 1', year: '2022' }
 */
export const formatStopTimeV2 = (
  datetime: string,
  timezone: string
): { time: string; date: string; year: string } => {
  const dateTimeInTimezone = dayjs(datetime).tz(timezone)
  const time = dateTimeInTimezone.format('h:mm A').toLowerCase() // e.g., 11:26 am
  const date = dateTimeInTimezone.format('ddd, MMM D') // e.g., Fri, Nov 17
  const year = dateTimeInTimezone.year().toString() // e.g., 2017
  return { time, date, year }
}

/**
 * Determines if two timestamps span multiple years.
 *
 * @param earliest - The earliest datetime string.
 * @param earliestTimezone - The timezone for the earliest datetime.
 * @param latest - The latest datetime string.
 * @param latestTimezone - The timezone for the latest datetime.
 * @returns True if the years are different, false otherwise.
 */
export const doTimestampsSpanMultipleYears = (
  earliest: string,
  earliestTimezone: string,
  latest: string,
  latestTimezone: string
): boolean => {
  const earliestYear = dayjs(earliest).tz(earliestTimezone).year()
  const latestYear = dayjs(latest).tz(latestTimezone).year()
  return earliestYear !== latestYear
}

/**
 * Calculates the time difference between two datetime strings in seconds.
 *
 * @param datetime1 - The earlier datetime string.
 * @param datetime2 - The later datetime string.
 * @returns The time difference in seconds.
 */
export const getTimeBetween = (
  datetime1: string,
  datetime2: string
): number => {
  return dayjs(datetime2).diff(dayjs(datetime1), 'second')
}

/**
 * Gets the formatted time difference between two datetime strings.
 *
 * @param datetime1 - The earlier datetime string.
 * @param datetime2 - The later datetime string.
 * @returns A human-readable string representing the time difference.
 */
export const getFormattedTimeBetween = (
  datetime1: string,
  datetime2: string
): string => {
  const timeBetween = getTimeBetween(datetime1, datetime2)
  const duration = secondsToDuration(timeBetween)
  return formatDuration(duration)
}

/**
 * Finds the earliest start date among an array of datetime strings.
 *
 * @param dates - An array of date strings.
 * @returns The earliest date string.
 */
export const getEarliestDate = (dates: string[]): string => {
  if (!dates || dates.length === 0) {
    return null;
  }

  return dates.reduce((earliestDate, date) => {
    if (!earliestDate || date < earliestDate) {
      return date;
    } else {
      return earliestDate;
    }
  }, dates[0]);
};

/**
 * Gets the minimized formatted time difference between two datetime strings.
 *
 * @param datetime1 - The earlier datetime string.
 * @param datetime2 - The later datetime string.
 * @returns A minimized human-readable string representing the time difference.
 */
export const getMinimizedFormattedTimeBetween = (
  datetime1: string,
  datetime2: string
): string => {
  const timeBetween = getTimeBetween(datetime1, datetime2)
  const duration = secondsToDuration(timeBetween)
  return formatDurationMinimized(duration)
}
