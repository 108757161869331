
import {
  ReservationDetail,
  HotelRequirement,
  HotelInformation,
  AmenityType,
  RequiredVehicle,
  Note,
} from '@/models/dto'
import { Vue, Component, Prop } from 'vue-property-decorator'
import TripAmenityCard from '@/components/TripAmenityCard.vue'
import TripNotes from '@/components/TripNotes.vue'
import TripMetadata from '@/components/TripMetadata.vue'
import TripItinerary from '@/components/TripItinerary.vue'
import TripRequirements from '@/components/TripRequirements.vue'
import { VehicleTypeId } from '@/utils/enum'
import { AmenityItem } from '@/models/AmenityItem'
import { getCharterBusAmenities, getMiniBusAmenities } from '@/utils/amenity'

const CUSTOMER_NOTE_TYPE = 2
@Component({
  components: {
    TripNotes,
    TripMetadata,
    TripAmenityCard,
    TripItinerary,
    TripRequirements,
  },
})
export default class TripInfoItineraryModal extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean
  @Prop({ type: String, required: false, default: 'Itinerary' }) readonly title: string
  @Prop({ type: Object, default: () => {}, required: false }) readonly reservation: ReservationDetail
  @Prop({ type: Object, default: () => {} }) readonly trip: any
  @Prop({ type: Array, default: () => [] }) readonly stops: any[]
  @Prop({ type: Boolean, default: false }) readonly allowReservationManagement: boolean

  get reservationStatus(): string {
    return this.reservation?.reservationStatus
  }

  get reservationId(): number {
    return this.reservation?.reservationId
  }

  get tripStops(): any[] {
    if (this.stops && this.stops.length) {
      return this.stops
    }
    return this.reservation?.stops
  }

  get hotelRequirement(): HotelRequirement {
    return this.reservation?.hotelRequirement
  }

  get hotelInformation(): HotelInformation {
    return this.reservation?.hotelInformation
  }

  get amenities(): AmenityType[] {
    return this.reservation?.amenities || []
  }

  get charterBusAmenities(): Array<AmenityItem> {
    return getCharterBusAmenities(this.amenities)
  }

  get miniBusAmenities(): Array<AmenityItem> {
    return getMiniBusAmenities(this.amenities)
  }

  get showCharterBusAmenities(): boolean {
    return this.charterBusAmenities?.length > 0 && this.charterBusOnTrip
  }

  get showMiniBusAmenities(): boolean {
    return this.miniBusAmenities?.length > 0 && this.miniBusOnTrip
  }

  get tripVehicles(): RequiredVehicle[] {
    return this.reservation?.requiredVehicles
  }

  get miniBusOnTrip(): boolean {
    return this.tripVehicles?.some(
      (tripVehicle) => tripVehicle?.vehicleType?.id === VehicleTypeId.MiniBus
    )
  }

  get charterBusOnTrip(): boolean {
    return this.tripVehicles?.some(
      (tripVehicle) => tripVehicle?.vehicleType?.id === VehicleTypeId.CharterBus
    )
  }

  get customerTripNotes(): Note[] {
    const notes = this.trip?.tripNotes
    if (!notes) {
      return null
    }
    return notes.filter((note) => note.noteType === CUSTOMER_NOTE_TYPE)
  }
}
