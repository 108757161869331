import axios from 'axios'

export const EnvironmentName = {
  unknown: 'unknown',
  local: 'local',
  dev: 'dev',
  staging: 'staging',
  production: 'production',
} as const
export type EnvironmentName = (typeof EnvironmentName)[keyof typeof EnvironmentName]
/**
 * Return the environment that this app is running in.
 */
export function resolveEnvironment(): EnvironmentName {
  // TODO: Use this everywhere we need to resolve the environment, to ensure
  // that we're using the same algorithm.
  // Use the hostname from the browser location bar.
  const browserHostname = window.location.host
  const hostParts = browserHostname.split(':')[0].split('.');
  let hostSubdomain = hostParts[0]

  // Map environment to its corresponding string value
  const envMap: Record<string, EnvironmentName> = {
    'dev': EnvironmentName.dev,
    'auto': EnvironmentName.staging,
    'app': EnvironmentName.production,
    'localhost': EnvironmentName.local,
  }

  const env: EnvironmentName = envMap[hostSubdomain] || null
  if (!envMap) {
    console.error("Could not determine environment name from browser location " + browserHostname)
    return EnvironmentName.unknown
  }

  return env
}

/**
 * Returns the given prefix with a trailing dot, if the prefix is not empty.
 *
 * @param prefix - The prefix to add a dot to.
 * @returns The prefix with a trailing dot, or an empty string if the prefix is empty.
 */
export const hostPrefix = (prefix: string): string =>
  prefix ? `${prefix}.` : ''

/**
 * Returns the base URL for the API.
 *
 * @param prefix - An optional prefix to use for the base URL.
 * @returns The base URL.
 */
export const baseUrl = (prefix = 'api'): string => {
  let host
  if (process.env.VUE_APP_BASE_URL_OVERRIDE) {
    host = process.env.VUE_APP_BASE_URL_OVERRIDE
  } else {
    const hostSubdomain = window.location.host.split(':')[0].split('.')[0]
    if (hostSubdomain === 'localhost') {
      host = 'dev.coachrail.com'
    } else {
      host = `${hostSubdomain}.coachrail.com`
    }
  }
  axios.defaults.baseURL = `https://${hostPrefix(prefix)}${host}`
  return `${hostPrefix(prefix)}${host}`
}

/**
 * Returns the base URL for the current host.
 *
 * @returns The base URL for the current host.
 */
export const hostBaseUrl = () => {
  let host
  const hostSubdomain = window.location.host.split(':')[0].split('.')[0]
  if (hostSubdomain === 'localhost') {
    host = 'dev.charterup.com'
  } else {
    host = `${hostSubdomain}.charterup.com`
  }
  return `https://${host}`
}

/**
 * Provides the Split config based on the subdomain of the current host.
 * @returns an environment of either "sandbox" or "live" and and applicationId
 */
export const splitKey = () => {
  const hostSubdomain = window.location.host.split(':')[0].split('.')[0]
  if (hostSubdomain === 'localhost' || hostSubdomain === 'dev') {
    return 'q65ir5g1n7pek8dn6f7rm4n0ua6patbsv69p'
  } else if (hostSubdomain === 'auto') {
    return 'q17bli4sobmq2riile3p8vm6vjdg9rkkcgr4'
  } else if (hostSubdomain === 'app') {
    return '4mfo5hbqg0cbkifhoocbtj26jukglmlbf1o9'
  }
}

/**
 * Provides the Finix config based on the subdomain of the current host.
 * @returns an environment of either "sandbox" or "live" and and applicationId
 */
export const finixConfig = (): { environment: 'sandbox' | 'live', applicationId: string } => {

  let hostSubdomain = window.location.host.split(':')[0].split('.')[0]
  if (hostSubdomain === 'localhost' || hostSubdomain === 'dev') {
    return {
      environment: "sandbox",
      applicationId: "APigGDxtKyaBfwqMAoM2RVRB"
    }
  } else if (hostSubdomain === 'auto') {
    return {
      environment: "sandbox",
      applicationId: "APigGDxtKyaBfwqMAoM2RVRB"
    }
  } else if (hostSubdomain === 'app') {
    return {
      environment: "live",
      applicationId: "APtkkr768DDXcQttsm2pahvz"
    }
  }

  return null

}
