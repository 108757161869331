var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.providerDetail)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.lgAndUp),expression:"!$vuetify.breakpoint.lgAndUp"}],ref:"mobileTripFooter",staticClass:"position-sticky bottom-0 left-0 z-10 background-white border-t-1 border-x-0 border-b-0 border-solid border-border-gray w-full min-h-80 d-flex align-center",style:({ transition: 'top 0.2s ease-in-out' })},[_c('v-row',{staticClass:"margin-t-2 margin-x-6",attrs:{"align":"center","justify":"center","dense":""}},[_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-col',{staticClass:"padding-r-0"},[_c('h4',[_vm._v("$"+_vm._s(_vm.providerDetail.totalAmount))]),_c('v-row',{attrs:{"dense":"","justify":"start","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('CStarRating',{style:({ 'margin-top': '2px' }),attrs:{"rating":_vm.providerDetail.company.rating}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}],style:({ 'min-width': '50px' }),attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.providerDetail.company.rating)+" ")])],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',[_c('CUIcon',{staticClass:"cursor-default margin-t-1",class:{
              'margin-r-4': _vm.$vuetify.breakpoint.xs,
              'margin-l-n3 margin-r-2': _vm.$vuetify.breakpoint.smAndUp,
            },attrs:{"view-box":"0 0 24 24","width":_vm.$vuetify.breakpoint.xs ? '30px' : '35px',"height":_vm.$vuetify.breakpoint.xs ? '30px' : '35px'}},[_vm._v(" verified_user ")]),_c('div',[_c('h5',{class:{
                'font-12': _vm.$vuetify.breakpoint.xs,
                'font-16': _vm.$vuetify.breakpoint.smAndUp,
              }},[_vm._v(" "+_vm._s(_vm.$t('bookingGuarantee.TITLE'))+" ")]),_c('BookingProtectionDialog')],1)],1)],1)],1),_c('v-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"d-flex flex-column w-full"},[(_vm.showShareQuoteDialog)?_c('ShareQuoteDialog',{attrs:{"quote-id":_vm.providerDetail.quote.quoteId,"button-class":"margin-t-2"}}):_vm._e(),_c('v-btn',{staticClass:"margin-y-2",attrs:{"id":"provider-button-checkout-mobile","block":"","small":"","color":"primary","to":{
            name: 'checkout',
            params: {
              quoteId: _vm.$route.params.quoteId,
              providerId: _vm.$route.params.providerId,
            },
          }}},[_vm._v(" "+_vm._s(_vm.$t('quoteDetail.billingSummary.CONTINUE_TO_BOOKING'))+" ")])],1)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }