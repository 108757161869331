
import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import DriverHotelRequirementsDialog from './DriverHotelRequirementsDialog.vue'
import ItineraryRequirementsDialog from './ItineraryRequirementsDialog.vue'
import HotelInformationDialog from './HotelInformationDialog.vue'
import TripModificationRequestDialog from './TripModificationRequestDialog.vue'
import { TranslateResult } from 'vue-i18n'
import { EventBus } from '@/utils/eventBus'
import { RiskTypeId, ReservationStatusKey, SplitFeatureFlag } from '@/utils/enum'
import {
  Risk,
  ReservationDetail,
  HotelInformation,
  HotelRequirement,
} from '@/models/dto'
import { ActionableRisk } from '@/models/ActionableRisk'
import split from '@/store/modules/split'

@Component({
  components: {
    DriverHotelRequirementsDialog,
    HotelInformationDialog,
    ItineraryRequirementsDialog,
    TripModificationRequestDialog
  },
})
export default class ReservationDetailWarningSummary extends Vue {
  @Prop({ required: true, type: Array }) readonly riskTypeIds: number[]
  @Prop({ required: true, type: Object }) readonly reservation: ReservationDetail

  isItineraryModificationWizardEnabled = false
  actionableRisk = new ActionableRisk()

  split = split
  @Watch('split.isReady', { immediate: true })
  async onSplitReady(isReady: boolean): Promise<void> {
    if (!isReady) {
      return
    }

    this.isItineraryModificationWizardEnabled = await split.isFeatureEnabled(
      SplitFeatureFlag.ItineraryModificationWizard
    )
  }

  @Watch('reservation', { immediate: true })
  reservationChanged(reservation) {
    this.actionableRisk.setReservationId(reservation?.reservationId)
    this.actionableRisk.setStops(reservation?.stops)
  }

  mounted(): void {
    EventBus.$on('open-hotel-info', () => {
      if (this.hotelRequirement) {
        this.openActionModal(RiskTypeId.DriverHotelMissing)
      }
    })
  }

  get hotelInformation(): HotelInformation {
    return this.reservation?.hotelInformation
  }

  get hotelRequirement(): HotelRequirement {
    return this.reservation?.hotelRequirement
  }

  get risksTypeIdsWithUniqueText(): number[] {
    const uniqueRiskTypeIds = new Set<number>()
    const uniqueRiskLabels = new Set<string>()
    for (const riskTypeId of this.riskTypeIds) {
      const label = this.riskObject(riskTypeId).riskText
      if (!uniqueRiskLabels.has(label)) {
        uniqueRiskLabels.add(label)
        uniqueRiskTypeIds.add(riskTypeId)
      }
    }

    return [...uniqueRiskTypeIds]
  }

  get hasPendingModificationRequest(): boolean {
    return this.reservation?.hasPendingModificationRequest
  }

  get isReservationUpcomingOrOnHold(): boolean {
    return (
      this.reservation?.reservationStatus === ReservationStatusKey.Upcoming ||
      this.reservation?.reservationStatus === ReservationStatusKey.OnHold
    )
  }

  showTripModification(riskTypeId: number): boolean {
    // We don't need to check if the user is allowed to manage the reservation
    // since we would only ever show these risk warnings if they could

    if([RiskTypeId.DriverHotelMissing, RiskTypeId.FlightInfoMissing].includes(riskTypeId)) {
      return false
    }

    return this.isItineraryModificationWizardEnabled && this.isReservationUpcomingOrOnHold
  }

  riskMessage(riskTypeId: number): TranslateResult {
    if (this.showTripModification(riskTypeId)) {
      return ' for all stops'
    }

    const riskText = this.riskObject(riskTypeId).riskText
    return this.$t('reservationDetail.informationNeeded.summary')[riskText]
      .MESSAGE
  }

  riskActionLink(riskTypeId: number): TranslateResult {
    if (this.showTripModification(riskTypeId)) {
      return 'Provide complete info'
    }

    const riskText = this.riskObject(riskTypeId).riskText
    return this.$t('reservationDetail.informationNeeded.summary')[riskText]
      .ACTION_LINK
  }

  riskInfoLink(riskTypeId: number): TranslateResult {
    const riskText = this.riskObject(riskTypeId).riskText
    return this.$t('reservationDetail.informationNeeded.summary')[riskText]
      .INFO_LINK
  }

  openActionModal(riskTypeId: number): void {
    this.riskObject(riskTypeId).openActionModal()
  }

  openInfoModal(riskTypeId: number): void {
    this.riskObject(riskTypeId).openInfoModal()
  }

  riskObject(riskTypeId: number) {
    return this.actionableRisk?.riskObject(riskTypeId)
  }

  openStartTripModificationDialog(): void {
    EventBus.$emit('open-trip-modification-dialog')
  }
}
