import { render, staticRenderFns } from "./TripModificationConfirmation.vue?vue&type=template&id=20f08808&scoped=true"
import script from "./TripModificationConfirmation.vue?vue&type=script&lang=ts"
export * from "./TripModificationConfirmation.vue?vue&type=script&lang=ts"
import style0 from "./TripModificationConfirmation.vue?vue&type=style&index=0&id=20f08808&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f08808",
  null
  
)

export default component.exports