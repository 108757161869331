var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    'cursor-pointer': _vm.clickable,
    'max-w-580': _vm.stacked,
    'w-400': _vm.carousel,
  },attrs:{"id":`${_vm.id}-bid-card-${_vm.bid.companyId}`},on:{"click":_vm.onClick}},[(_vm.bid)?_c('div',{staticClass:"background-transparent border-solid z-1 h-full border-radius-8",class:_vm.borderClasses,style:({ 'box-shadow': _vm.featured ? `0 0 12px ${_vm.$vuetify.theme.themes.light.primary}` : '' })},[_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.stacked }},[_c('div',{staticClass:"d-flex flex-column padding-a-0 overflow-hidden background-border-gray",class:{ 'col-12': _vm.stacked, 'col-4': !_vm.stacked },style:({
          'border-radius': !_vm.stacked ? '4px 0 0 4px' : '4px 4px 0 0',
          'width': _vm.stacked ? '100%' : '33.3333%',
          'max-width': _vm.stacked ? '100%' : '33.3333%',
        })},[_c('div',{staticClass:"position-relative"},[_c('v-img',{staticClass:"object-fit-cover margin-a-0 z-0 border-radius-top-left-4 border-radius-bottom-right-0",class:{
              'border-radius-top-right-4 border-radius-bottom-left-0': _vm.stacked,
              'border-radius-top-right-0 border-radius-bottom-left-4': !_vm.stacked,
              'opacity-50': !_vm.isValidBid,
            },attrs:{"src":_vm.companyPhotoPath || _vm.fallBackImage,"aspect-ratio":_vm.stacked ? _vm.aspectRatio : null,"height":_vm.stacked ? 155 : 173}}),(_vm.bid.preferred)?_c('BidCardCertifiedPartnerBadge',{attrs:{"carousel":_vm.carousel}}):_vm._e()],1)]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 position-relative",class:{
          'margin-t-3': !_vm.stacked,
          'margin-t-2': _vm.stacked,
        },style:({ 'width': _vm.stacked ? '100%' : '66%' })},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex flex-column",class:{
              'margin-l-5': !_vm.stacked,
              'margin-l-3': _vm.stacked,
              'margin-t-1': _vm.carousel,
            },style:({
              'max-width':
                _vm.priceCharCountMoreThan7 && !_vm.stacked
                  ? 'calc(100% - 220px)'
                  : 'calc(100% - 165px)',
            })},[_c('h2',{staticClass:"overflow-hidden text-overflow-ellipsis",class:{
                'font-16': _vm.stacked,
                'font-18': !_vm.stacked,
                'opacity-50': !_vm.isValidBid,
              }},[_vm._v(" "+_vm._s(_vm.bid.companyDBAName)+" ")]),(_vm.isValidBid || !_vm.stacked)?[_c('BidCardRatingAndReviews',{class:{ 'margin-t-1': _vm.carousel },attrs:{"bid":_vm.bid,"stacked":_vm.stacked}}),_c('BidCardAmenities',{attrs:{"bid":_vm.bid,"is-bronze":_vm.isBronze}})]:_vm._e()],2),_c('BidCardPricing',{attrs:{"bid":_vm.bid,"stacked":_vm.stacked,"isValidBid":_vm.isValidBid,"charCountMoreThan7":_vm.priceCharCountMoreThan7,"showUsd":_vm.showUsd}})],1),_c('v-spacer'),_c('div',{staticClass:"d-flex margin-t-0 align-center"},[(_vm.isValidBid)?[_c('v-spacer'),(!_vm.stacked)?_c('v-btn',{staticClass:"primary w-140 margin-r-5 margin-b-5",attrs:{"id":`${_vm.id}-bid-card-${_vm.bid.companyId}`,"to":_vm.goTo,"disabled":!_vm.clickable,"small":""}},[_vm._v(" "+_vm._s(_vm.$t('common.CONTINUE'))+" ")]):_vm._e()]:_c('BidCardSoldOut',{attrs:{"stacked":_vm.stacked}})],2)],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }