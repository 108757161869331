
import { Vue, Component, Prop } from 'vue-property-decorator'
import StarRating from 'vue-star-rating'

@Component({
  components: {
    StarRating,
  },
})
export default class CStarRating extends Vue {
  @Prop({ type: Number, default: 0 }) readonly rating: number
  @Prop({ type: Number, default: 16 }) readonly starSize: number
}
