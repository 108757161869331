import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store'
import { TripModificationStep } from '@/models/TripModification'
import {
  TripModificationTrip as TripModificationTripDTO,
  ModifiedTrip as ModifiedTripDTO,
  TripEstimationRequest
} from '@/models/dto'
import { TripModificationTrip } from '@/classes/TripModificationTrip'
import footer from '@/store/modules/footer'
import { WizardStore } from '../WizardStore'
import service from '@/services/tripModification'
import quotes from '@/services/quotes'
import { TripModificationStepKey } from '@/utils/enum'
import { WizardVehicle } from '@/models/dto'
import { roundSecondsToNearestXMinutes } from '@/utils/datetime'
import { estimateDropoffDatetime } from '@/utils/stop'

const SELF_SERVE_DRIVE_TIME_MULTIPLIER = 1.1
const MINIMUM_TRAVEL_TIME = 15
@Module({ generateMutationSetters: true })
class TripModificationModule extends VuexModule
  implements WizardStore<TripModificationStep> {
  _managedId: string = null
  _routeName: string = null
  _currentTrip: TripModificationTripDTO = null
  _requestedTrip: TripModificationTripDTO = null
  _requestedTripForReview: ModifiedTripDTO = null
  _tripModificationId: number = null
  _isOpen = false
  _step: TripModificationStep = null
  _steps: TripModificationStep[] = [
    {
      component: () => import('@/components/TripModificationItinerary.vue'),
      key: TripModificationStepKey.Itinerary,
      orderIndex: 0,
    },
    {
      component: () => import('@/components/TripModificationVehicle.vue'),
      key: TripModificationStepKey.Vehicle,
      orderIndex: 1,
    },
    {
      component: () => import('@/components/TripModificationReview.vue'),
      key: TripModificationStepKey.Review,
      orderIndex: 2,
    },
    {
      component: () => import('@/components/TripModificationConfirmation.vue'),
      key: TripModificationStepKey.Confirmation,
      orderIndex: 3,
      excludeFromStepCount: true,
    },
  ]
  _forwardButtonDisabled = false

  /**
   * Gets the steps.
   * @returns The steps.
   */
  get steps(): TripModificationStep[] {
    return this._steps
  }

  /**
   * Gets the current step.
   * @returns The current step.
   */
  get step(): TripModificationStep {
    return this._step
  }

  /**
   * Gets the total number of steps.
   * @returns The total number of steps.
   */
  get stepCount(): number {
    return this._steps.length
  }

  /**
   * Gets the current step index.
   * @returns The current step index.
   */
  get currentStepIndex(): number {
    return this.steps.findIndex(({ key }) => key === this.step.key)
  }

  get isOpen(): boolean {
    return this._isOpen
  }

  get managedId(): string {
    return this._managedId
  }

  get routeName(): string {
    return this._routeName
  }

  get currentTrip(): TripModificationTripDTO {
    return this._currentTrip
  }

  get requestedTrip(): TripModificationTripDTO {
    return this._requestedTrip
  }

  get requestedTripForReview(): ModifiedTripDTO {
    return this._requestedTripForReview
  }

  get tripModificationId(): number {
    return this._tripModificationId
  }

  get forwardButtonDisabled(): boolean {
    return this._forwardButtonDisabled
  }

  @Action
  setIsOpen(value: boolean): void {
    this._isOpen = value
    this._step = this._steps[0]
    footer.setShow(!value)
  }

  @Action
  setStep(step: TripModificationStep): void {
    this._step = step
  }

  @Action
  setManagedId(id: string): void {
    this._managedId = id
  }

  @Action
  setRouteName(name: string): void {
    this._routeName = name
  }

  @Action
  async nextStep(): Promise<void> {
    if (this.currentStepIndex === this.steps.length - 1) {
      return
    }
    const nextStep = this.steps[this.currentStepIndex + 1]
    this.setStep(nextStep)
  }

  @Action
  previousStep(): void {
    if (this.currentStepIndex === 0) {
      return
    }
    const previousStep = this.steps[this.currentStepIndex - 1]
    this.setStep(previousStep)
  }

  @Action
  setRequestedAda(ada: boolean): void {
    this._requestedTrip.ada = ada
  }

  @Action
  setRequestedSpab(spab: boolean): void {
    this._requestedTrip.spab = spab
  }

  @Action
  setRequestedPassengerCount(passengerCount: number): void {
    this._requestedTrip.passengerCount = passengerCount
  }

  @Action
  setRequestedVehicles(vehicles: WizardVehicle[]): void {
    this._requestedTrip.vehicles = vehicles
  }

  @Action
  setForwardButtonDisabled(forwardButtonDisabled: boolean): void {
    this._forwardButtonDisabled = forwardButtonDisabled
  }

  /**
   * Starts trip modification process by fetching itinerary information. Upon
   * success, the current trip is set to the fetched trip.
   *
   * @param managedId - The reservation managed ID to use to get the trip itinerary information.
   * @returns nothing.
   */
  @Action
  async start(): Promise<void> {
    if (!this._managedId) {
      return
    }

    try {
      const res = await service.start(this._managedId)
      const trip = new TripModificationTrip(res.data)
      this._currentTrip = trip
      this._requestedTrip = trip
      await this.estimateTrip();
    } catch (error) {
      console.error(error)
    }
  }

  @Action
  async reviewModification(): Promise<void> {
    if (!this._managedId) {
      return
    }

    try {
      const payload = {
        managedId: this._managedId,
        requestedTrip: this._requestedTrip,
      }
      const res = await service.review(payload)
      this._currentTrip = res.data.currentTrip
      this._requestedTripForReview = res.data.requestedTrip
    } catch (error) {
      this.setForwardButtonDisabled(true)
      console.error(error)
    }
  }

  /**
   * Submits and saves a trip modification request.
   *
   * @param managedId - The reservation managed ID to use to get the trip itinerary information.
   * @param requestedTrip - The requested trip modifications.
   * @returns nothing.
   */
  @Action
  async submitModification(): Promise<void> {
    if (!this._managedId) {
      return
    }

    try {
      const requestedTrip = new TripModificationTrip(
        this._requestedTripForReview
      )
      const res = await service.confirm({
        managedId: this._managedId,
        requestedTrip,
      })
      this._tripModificationId = res.data.tripModificationRequestId
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Loads an existing trip modification request.
   *
   * @param managedId - The reservation managed ID to use to get the trip modification request information.
   * @returns nothing.
   */
  @Action
  async getPendingModification(): Promise<void> {
    if (!this._managedId) {
      return
    }

    try {
      const res = await service.success(this._managedId)
      this._currentTrip = res.data.currentTrip
      this._requestedTripForReview = res.data.requestedTrip
    } catch (error) {
      console.error(error)
    }
  }

  @Action
  async estimateTrip(): Promise<void> {
    if (!this._requestedTrip) {
      return
    }

    try {
      const payload = this.getTripEstimateRequest()
      const response = await quotes.tripEstimation(payload)
      const tripEstimate = response?.data?.[0]
      if (!tripEstimate) {
        console.warn('Unable to estimate trip')
        return
      }

      for (const stop of this._requestedTrip.stops) {
        const secondsFromPreviousStop =
          tripEstimate.timesFromPreviousStop[stop.orderIndex]
        const travelTimeFromPreviousStopInSeconds = this.calculateTravelTime(
          secondsFromPreviousStop
        )
        stop.travelTimeFromPreviousStopInSeconds = travelTimeFromPreviousStopInSeconds

        const dropoffDatetime = this.calculateDropoffDatetime(
          stop.orderIndex,
          travelTimeFromPreviousStopInSeconds,
          stop.address?.zoneId
        )
        stop.dropoffDatetime = dropoffDatetime
      }
    } catch (error) {
      console.error(error)
    }
  }

  getTripEstimateRequest(): TripEstimationRequest {
    return {
      trips: [
        {
          stops: this._requestedTrip.stops.map((stop) => {
            return {
              address: {
                name: stop.address?.name,
                country: stop.address?.country,
                lat: stop.address?.lat,
                lng: stop.address?.lng,
                zoneId: stop.address?.zoneId,
              },
              active: true,
              orderIndex: stop.orderIndex,
              pickupDatetime: stop.pickupDatetime,
            }
          }),
          vehicles: this._requestedTrip.vehicles.map(({ vehicleTypeKey }) => ({
            vehicleTypeKey,
          })),
        },
      ],
    }
  }

  calculateTravelTime(secondsFromPreviousStop: number): number {
    if (secondsFromPreviousStop === null) {
      return null
    }
    const duration = Math.max(
      secondsFromPreviousStop * SELF_SERVE_DRIVE_TIME_MULTIPLIER,
      MINIMUM_TRAVEL_TIME
    )
    return roundSecondsToNearestXMinutes(duration, MINIMUM_TRAVEL_TIME)
  }

  calculateDropoffDatetime(
    stopIndex: number,
    travelTimeFromPreviousStopInSeconds: number,
    zoneId: string
  ): string | null {
    if (stopIndex === 0) {
      return null
    }
    const previousStopPickupTime = this._requestedTrip?.stops[stopIndex - 1]
      .pickupDatetime
    const estimatedDropoffDatetime = estimateDropoffDatetime(
      previousStopPickupTime,
      travelTimeFromPreviousStopInSeconds,
      zoneId
    )
    return estimatedDropoffDatetime?.toISO() || null
  }


}

export default new TripModificationModule({ store, name: 'tripModification' })
