
import { Vue, Component } from 'vue-property-decorator'
import tripModificationStore from '@/store/modules/tripModification'
import { TripModificationStepKey } from '@/utils/enum'
import { EventBus } from '@/utils/eventBus'

type ButtonProps = {
  showArrow?: boolean
  label: string
  handleClick: () => any
}

@Component
export default class TripModificationFooter extends Vue {
  submitting = false
  error = false

  get stepKey(): TripModificationStepKey {
    return tripModificationStore.step.key
  }

  get forwardStepButton(): ButtonProps {
    return this.forwardButtonMap[this.stepKey]
  }

  get backStepButton(): ButtonProps | null {
    return this.backButtonMap?.[this.stepKey] || null
  }

  get backButtonMap(): Record<string, ButtonProps> {
    return {
      [TripModificationStepKey.Vehicle]: {
        label: 'Back',
        handleClick: () => tripModificationStore.previousStep(),
      },
      [TripModificationStepKey.Review]: {
        label: 'Back',
        handleClick: () => tripModificationStore.previousStep(),
      },
    }
  }

  get forwardButtonMap(): Record<string, ButtonProps> {
    return {
      [TripModificationStepKey.Itinerary]: {
        label: 'Continue',
        showArrow: true,
        handleClick: () => tripModificationStore.nextStep(),
      },
      [TripModificationStepKey.Vehicle]: {
        label: 'Review',
        showArrow: true,
        handleClick: () => this.submitModificationForReview(),
      },
      [TripModificationStepKey.Review]: {
        label: 'Submit Request',
        handleClick: async () => {
          await this.submitReviewForConfirmation()
          EventBus.$emit('refresh-reservation-detail')
        },
      },
      [TripModificationStepKey.Confirmation]: {
        label: 'View Booking',
        handleClick: () => tripModificationStore.setIsOpen(false),
      },
    }
  }

  get forwardButtonDisabled(): boolean {
    return tripModificationStore.forwardButtonDisabled
  }

  get styles(): Record<string, string> {
    return {
      'max-width': `${this.$vuetify.breakpoint.thresholds.md}px`,
    }
  }

  get continueButtonWidth(): string | number {
    return this.$vuetify.breakpoint.smAndUp
      ? 265
      : this.backStepButton
      ? '60%'
      : '100%'
  }

  // Submits the requested modification for review
  async submitModificationForReview(): Promise<void> {
    this.submitting = true
    await tripModificationStore.reviewModification()
    this.submitting = false
    if (!tripModificationStore.forwardButtonDisabled) {
      tripModificationStore.nextStep()
    }
  }

  // Submits the reviewed modification for confirmation
  async submitReviewForConfirmation(): Promise<void> {
    this.submitting = true
    await tripModificationStore.submitModification()
    this.submitting = false
    tripModificationStore.nextStep()
  }
}
