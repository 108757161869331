import { Action, Mutation, Module, VuexModule } from 'vuex-class-modules'
import { SplitFactory } from '@splitsoftware/splitio'
import {
  SplitTrafficType,
  SplitFeatureFlag,
  SplitKeyType,
  SplitEvents,
} from '@/utils/enum'
import store from '@/store/index'
import { splitKey } from '@/utils/env'
import { QuoteConvertedEventProperties } from '@/models/Split'
import { datadogRum } from '@datadog/browser-rum'
@Module({ generateMutationSetters: true })
class SplitModule extends VuexModule {
  _key: string = null
  _splitClient: SplitIO.IClient = null
  _isReady: boolean = false
  _attributes: SplitIO.Attributes = {}

  /**
   * Is the Split client ready to be used.
   * @returns the Split client status.
   */
  get isReady(): boolean {
    return this._isReady
  }

  get trafficType(): SplitTrafficType {
    return this._key === SplitKeyType.AnonymousUser || !this._key
      ? SplitTrafficType.Anonymous
      : SplitTrafficType.User
  }

  get splitFactory(): SplitIO.ISDK {
    let key =
      this.trafficType === SplitTrafficType.Anonymous
        ? SplitKeyType.AnonymousUser
        : this._key
    return SplitFactory({
      core: {
        authorizationKey: splitKey(),
        trafficType: this.trafficType,
        key,
      },
      startup: {
        readyTimeout: 1.5,
      },
      impressionListener: {
        logImpression(impressionData) {
          datadogRum
            .addFeatureFlagEvaluation(
              impressionData.impression.feature,
              impressionData.impression.treatment
            );
        },
      },
    })
  }

  /**
   * Updates the attributes associated with the user.
   * @param attributes - the attribute map for a given user.
   */
  @Mutation
  setClientAttributes(attributes: SplitIO.Attributes): void {
    this._attributes = attributes
  }

  /**
   * Check whether a feature flag is enabled.
   * @param flag - the feature flag to check.
   * @returns true if the feature flag is enabled, false otherwise.
   */
  @Action
  async isFeatureEnabled(flag: SplitFeatureFlag): Promise<boolean> {
    if (!this.isReady || !flag) {
      return false
    }
    const treatment = (await this._splitClient.getTreatment(flag, this._attributes)) === 'on'
    return treatment
  }

  /**
   * Return the treatment for a feature flag.
   * @param flag - the feature flag to check.
   * @returns the treatment for a feature flag.
   */
  @Action
  async getTreatmentConfig(flag: SplitFeatureFlag): Promise<SplitIO.TreatmentWithConfig> {
    const treatment: SplitIO.TreatmentWithConfig = await this._splitClient.getTreatmentWithConfig(flag, this._attributes)
    return treatment
  }

  /**
   * Updates the current key and reinitializes client if it's new.
   * @param key - the key to update.
   * @returns void
   */
  @Action
  async updateKey(
    key: SplitKeyType | string = SplitKeyType.AnonymousUser
  ): Promise<void> {
    const shouldReinitialize = this._key !== key
    this._key = key
    if (shouldReinitialize) {
      await this.initializeClient()
    }
  }

  /**
   * Create a new client and reinitializes if existing.
   * @returns void
   */
  @Action
  async initializeClient(): Promise<void> {
    if (this._splitClient !== null) {
      await this._splitClient.destroy()
      this._isReady = false
    }

    try {
      const trafficType =
        this._key === SplitKeyType.AnonymousUser
          ? SplitTrafficType.Anonymous
          : SplitTrafficType.User
      const client = this.splitFactory.client(this._key, trafficType)
      await client.ready().catch((e) => {
        throw e
      })
      this._splitClient = client
      this._isReady = true
    } catch (e) {
      console.warn(e)
    }
  }

  /**
   * Track the 'quote_created' event in split.
   * @returns a boolean indicating whether the event was successfully recorded
   */
  @Action
  async trackQuoteCreated(): Promise<boolean> {
    const success = await this._splitClient.track(SplitEvents.QuoteCreated)
    return success
  }

  /**
   * Track the 'quote_converted' event in split including the totalAmount of the quote and other properties.
   * @returns a boolean indicating whether the event was successfully recorded
   */
  @Action
  async trackQuoteConverted(
    properties: QuoteConvertedEventProperties
  ): Promise<boolean> {
    const success = await this._splitClient.track(
      SplitEvents.QuoteConverted,
      properties.totalAmount,
      properties
    )
    return success
  }

  /**
   * Track the 'guest-quote_converted' event in split including the totalAmount
   * @returns a boolean indicating whether the event was successfully recorded
   */
  @Action
  async trackGuestQuoteConverted(totalAmount: number): Promise<boolean> {
    const success = await this._splitClient.track(
      SplitEvents.GuestQuoteConverted,
      totalAmount
    )
    return success
  }
}

export default new SplitModule({ store, name: 'split' })
