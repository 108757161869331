
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import { mapStyles } from '@/components/mapStyles'
import { gmapApi } from 'vue2-google-maps'
import LiveTrackingMapShareButton from '@/components/LiveTrackingMapShareButton.vue'
import LiveTrackingMapToggleGroup from '@/components/LiveTrackingMapToggleGroup.vue'
import LiveTrackingVehicle from '@/components/LiveTrackingVehicle.vue'
import LiveTrackingMapStopIcon from '@/components/LiveTrackingMapStopIcon.vue'
import LiveTrackingPickupMarker from '@/components/LiveTrackingPickupMarker.vue'
import tracking from '@/store/modules/tracking'
import split from '@/store/modules/split'
import trackingVehicleStore from '@/store/modules/trackingVehicles'
import { TrackingVehicle, Location } from '../models/dto/TrackingVehicle'
import { LiveTrackingStatus } from '@/utils/enum'
import { applyOpacity } from '@/utils/color'
import { formatDisplayTime } from '@/utils/string'
import { DEFAULT_MAP_THEME_COLOR } from '@/views/LiveTracking.vue'
import { PICKUP_MARKER_OFFSETS } from '@/utils/tracking'
import { TrackingStop } from '@/models/dto/TrackingStop'

const MAX_AUTO_ZOOM = 18
const MIN_AUTO_ZOOM = 11

let LiveTrackingMapStopIconClass = Vue.extend(LiveTrackingMapStopIcon)
let LiveTrackingPickupMarkerClass = Vue.extend(LiveTrackingPickupMarker)

@Component({
  components: {
    LiveTrackingMapShareButton,
    LiveTrackingMapToggleGroup,
    LiveTrackingVehicle,
    LiveTrackingMapStopIcon,
  },
})
export default class LiveTrackingMap extends Vue {
  @Prop({ type: Boolean, required: true }) readonly refreshMap!: boolean

  @Watch('trackingVehicleStore.activeTrackingVehicle')
  async onActiveVehicleUpdate() {
    this.clearStopMarkers()
    this.clearRoutePaths()
    await this.setupRoute()
    this.updateStops()
  }

  @Watch('refreshMap')
  async onRefreshMapChanged(value): Promise<void> {
    if (!value) {
      return
    }
    await this.updateMap()
    this.$emit('map-refreshed')
  }

  @Watch('directionsServiceRequestCounter')
  onDirectionsServiceRequestCounterChanged(): void {
    if (this.directionsServiceRequestCounter >= 100) {
      this.directionsServiceRequestCounter = 0
      this.initializeDirectionsService()
    }
  }

  @Watch('tracking.isFinished')
  async onIsFinishedChanged(value: boolean): Promise<void> {
    if (!value) {
      return
    }
    this.clearMap()
    await this.setupRoute()
    this.updateStops()
    await this.trackingVehicleStore.setActiveTrackingVehicle(null)
  }

  tracking = tracking
  trackingVehicleStore = trackingVehicleStore
  split = split
  mapConfig = {
    center: { lat: 33.8458485, lng: -84.3716548 },
    zoom: 18,
    options: {
      clickableIcons: false,
      streetViewControl: false,
      fullScreenControl: true,
      mapTypeControl: false,
      styles: mapStyles,
      fullscreenControlOptions: {
        position: -1,
      },
      gestureHandling: 'greedy',
    },
  }
  map = null
  bounds = null

  stopsToDisplay: {
    isNextStop: boolean
    orderIndex: number
    isComplete: boolean
    waypointIndex: number
    stopIndex: number
    stopTime: string
    lat: number
    lng: number
    id: string
  }[] = []
  stopMarkers = []
  pickupStopMarkers = []
  activeRoute = null
  stopInfoWindows = []

  directionsService = null
  directionsServiceRequestCounter = 0
  directionDisplays = []

  get google(): any {
    return gmapApi()
  }

  get trackingVehicles(): TrackingVehicle[] {
    return this.trackingVehicleStore.trackingVehicles
  }

  get activeTrackingVehicle(): TrackingVehicle {
    return this.trackingVehicleStore.activeTrackingVehicle
  }

  get disableZoomModes(): boolean {
    return (
      tracking.reservation.reservationStatus !== 'started' ||
      this.trackingVehicles.length === 0
    )
  }

  get centeringMode(): string {
    if (tracking.zoomOptions.customPosition) {
      return 'custom'
    }
    if (
      tracking.zoomOptions.fitEntireRoute ||
      tracking.reservation.reservationStatus !== 'started' ||
      this.trackingVehicles.length === 0
    ) {
      return 'full-route'
    }
    if (
      tracking.zoomOptions.fitNextStop ||
      tracking.zoomOptions.fitPreviousStop
    ) {
      if (!tracking.zoomOptions.fitPreviousStop) {
        return 'next-stop'
      }
      if (!tracking.zoomOptions.fitNextStop) {
        return 'previous-stop'
      }
      return 'previous-and-next-stop'
    }
    return 'center'
  }

  get isUpcoming(): boolean {
    return ['upcoming', 'hold'].includes(tracking.reservation.reservationStatus)
  }

  get isInProgress(): boolean {
    return tracking.reservation.reservationStatus === 'started'
  }

  get activeVehicleColor(): string {
    return (
      trackingVehicleStore?.activeTrackingVehicle?.color ||
      DEFAULT_MAP_THEME_COLOR
    )
  }

  get routeColor(): string {
    const opacity = (tracking.disableTracking) ? 1 : 0.4
    return applyOpacity(this.activeVehicleColor, opacity)
  }

  async mounted(): Promise<void> {
    this.map = await (this.$refs.gMap as any).$mapPromise
    this.bounds = new this.google.maps.LatLngBounds()
    this.initializeDirectionsService()
    await this.trackingVehicleStore.processVehicles()
    await this.setupRoute()
    setTimeout(() => {
      this.map.addListener('zoom_changed', () => this.handleCenterChanged())
      this.map.addListener('drag', () => this.handleCenterChanged())
    }, 1000)
    this.handleCenterChanged()
  }

  async setupRoute(): Promise<void> {
    this.setupStopsToDisplay()
    await this.drawRouteStopMarkers()
    this.drawRoutePaths()
  }

  setupStopsToDisplay() {
    const stopsToDisplay = []
    for (const [waypointIndex, waypoint] of tracking.waypoints.entries()) {
      selectStop: for (const [stopIndex, stop] of waypoint.stops.entries()) {
        const isNextStop = tracking.isNext(stop)
        const isComplete = tracking.isComplete(stop)
        const isLastStopInWaypoint = stopIndex === waypoint.stops.length - 1
        if (isNextStop || !isComplete || isLastStopInWaypoint) {
          const data = {
            isNextStop,
            orderIndex: stop.orderIndex,
            isComplete,
            waypointIndex,
            stopIndex,
            stopTime: stop.pickupDatetime || stop.dropoffDatetime,
            lat: stop.address.lat,
            lng: stop.address.lng,
            id: `${stop.address.lat}-${stop.address.lng}`,
            timeZone: stop.address.timeZone,
          }
          stopsToDisplay.push(data)
          break selectStop
        }
      }
    }
    this.stopsToDisplay = stopsToDisplay
  }

  handleCenterChanged(): void {
    tracking.setCustomZoomPosition(true)
    this.makePickupStopMarker(tracking.pickupStop)
  }

  handleRecenter(): void {
    this.centerMap()
    setTimeout(() => {
      tracking.setCustomZoomPosition(false)
    }, 0)
  }

  async centerMap(): Promise<void> {
    switch (this.centeringMode) {
      case 'custom':
        tracking.setCustomZoomPosition(true)
        break
      case 'full-route':
        this.centerOnFullRoute()
        break
      case 'center':
        await this.centerOnBus()
        break
      case 'next-stop':
        await this.fitNextStop()
        break
      case 'previous-stop':
        await this.fitPreviousStop()
        break
      case 'previous-and-next-stop':
        await this.fitPreviousStopAndNextStop()
        break
    }
  }

  centerOnFullRoute(): void {
    this.extendBounds(this.stopMarkers.map((marker) => marker.getPosition()))
    this.map.fitBounds(this.bounds)
    tracking.setCustomZoomPosition(false)
  }

  async centerOnBus(): Promise<void> {
    this.bounds = new this.google.maps.LatLngBounds()
    this.extendBounds([
      this.activeTrackingVehicle.location,
      this.activeTrackingVehicle.previousLocation,
    ])
    await this.map.panTo(this.activeTrackingVehicle.location)
    const zoomLevel = Math.min(
      this.getBoundsZoomLevel(this.bounds) - 1,
      MAX_AUTO_ZOOM
    )
    this.map.setZoom(zoomLevel)
    tracking.setCustomZoomPosition(false)
  }

  async fitNextStop(): Promise<void> {
    if (!tracking.nextStop) {
      return
    }
    const nextStopAddress = tracking.nextStop?.address
    const nextStopLocation = {
      lat: nextStopAddress?.lat,
      lng: nextStopAddress?.lng,
    }

    this.bounds = new this.google.maps.LatLngBounds()
    this.extendBounds([
      this.activeTrackingVehicle.location,
      this.activeTrackingVehicle.previousLocation,
      nextStopLocation,
    ])

    await this.map.panTo(this.bounds.getCenter())
    this.map.setZoom(this.getBoundsZoomLevel(this.bounds) - 1)
    tracking.setCustomZoomPosition(false)
  }

  async fitPreviousStop(): Promise<void> {
    if (!tracking.previousStop) {
      return
    }
    const previousStopAddress = tracking.previousStop?.address
    const previousStopLocation = {
      lat: previousStopAddress?.lat,
      lng: previousStopAddress?.lng,
    }

    this.bounds = new this.google.maps.LatLngBounds()
    this.extendBounds([
      this.activeTrackingVehicle.location,
      this.activeTrackingVehicle.previousLocation,
      previousStopLocation,
    ])

    await this.map.panTo(this.bounds.getCenter())
    this.map.setZoom(this.getBoundsZoomLevel(this.bounds) - 1)
    tracking.setCustomZoomPosition(false)
  }

  async fitPreviousStopAndNextStop(): Promise<void> {
    if (!tracking.nextStop || !tracking.previousStop) {
      return
    }
    const nextStopAddress = tracking.nextStop?.address
    const nextStopLocation = {
      lat: nextStopAddress?.lat,
      lng: nextStopAddress?.lng,
    }
    const previousStopAddress = tracking.previousStop?.address
    const previousStopLocation = {
      lat: previousStopAddress?.lat,
      lng: previousStopAddress?.lng,
    }

    this.bounds = new this.google.maps.LatLngBounds()
    this.extendBounds([
      this.activeTrackingVehicle.location,
      this.activeTrackingVehicle.previousLocation,
      nextStopLocation,
      previousStopLocation,
    ])

    await this.map.panTo(this.bounds.getCenter())
    this.map.setZoom(this.getBoundsZoomLevel(this.bounds) - 1)
    tracking.setCustomZoomPosition(false)
  }

  getBoundsZoomLevel(bounds): number {
    const mapDim = {
      width: (this.$refs.gMap as any).$el.offsetWidth,
      height: (this.$refs.gMap as any).$el.offsetHeight,
    }

    const WORLD_DIM = { height: 256, width: 256 }

    function latRad(lat) {
      const sin = Math.sin((lat * Math.PI) / 180)
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
    }

    function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
    }

    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()

    const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI

    const lngDiff = ne.lng() - sw.lng()
    const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction)
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction)

    const maxLimitedZoom = Math.min(latZoom, lngZoom, MAX_AUTO_ZOOM)
    return Math.max(maxLimitedZoom, MIN_AUTO_ZOOM)
  }

  clearMap(): void {
    this.bounds = new this.google.maps.LatLngBounds()
    this.clearStopMarkers()
    this.clearRoutePaths()
    this.clearInfoWindows()
  }

  clearStopMarkers(): void {
    for (const marker of this.stopMarkers) {
      marker.setMap(null)
    }
    this.stopMarkers = []
  }

  clearPickupStopMarkers(): void {
    for (const marker of this.pickupStopMarkers) {
      marker.setMap(null)
    }
    this.pickupStopMarkers = []
  }

  clearRoutePaths(): void {
    for (const dir of this.directionDisplays) {
      dir.setMap(null)
    }
    this.directionDisplays = []
  }

  clearInfoWindows(): void {
    this.clearStopInfoWindows()
  }

  clearStopInfoWindows(): void {
    for (const infoWindow of this.stopInfoWindows) {
      infoWindow.close()
    }
    for (const infoWindow of this.stopInfoWindows) {
      infoWindow.setMap(null)
    }
    this.stopInfoWindows = []
  }

  async drawRouteStopMarkers(): Promise<void> {
    for (const stop of this.stopsToDisplay) {
      this.makeStopMarker(stop)
    }
    this.makePickupStopMarker(tracking.pickupStop)
    await this.centerMap()
  }

  initializeDirectionsService(): void {
    this.directionsService = (() => {
      const ds = new this.google.maps.DirectionsService()
      let counter = 1
      return (dOpts, dc) => {
        counter++
        setTimeout(() => {
          ds.route(dOpts, dc)
          counter--
        }, 80 * counter)
      }
    })()
  }

  drawRoutePaths(): void {
    const stopLocations = tracking.orderedStops.map((stop) => {
      return {
        lat: stop.address.lat,
        lng: stop.address.lng,
      }
    })

    // Divide route to several parts because max stations limit is 25 (23 waypoints + 1 origin + 1 destination)
    const parts = []
    const max = 24
    for (let i = 0; i < stopLocations.length; i = i + max) {
      parts.push(stopLocations.slice(i, i + max + 1))
    }

    const strokeColor = tracking.isFinished
      ? this.$vuetify.theme.themes.light.black
      : this.routeColor
    const polylineGeneralStopOptions = {
      strokeColor,
      strokeOpacity: 1,
      fillOpacity: 1,
      strokeWeight: 5,
      zIndex: 3,
    }
    const directionsDisplay = new this.google.maps.DirectionsRenderer({
      polylineOptions: polylineGeneralStopOptions,
      preserveViewport: true,
      suppressMarkers: true,
    })
    this.directionDisplays.push(directionsDisplay)
    // Send requests to service to get route (for stations count <= 25 only one request will be sent)
    for (let i = 0; i < parts.length; i++) {
      // Waypoints does not include first station (origin) and last station (destination)
      const waypoints = []
      for (let j = 1; j < parts[i].length - 1; j++) {
        waypoints.push({ location: parts[i][j], stopover: false })
      }
      // Service options
      const directionsOptions = {
        origin: parts[i][0],
        destination: parts[i][parts[i].length - 1],
        waypoints,
        travelMode: 'DRIVING',
      }
      // Send request
      this.directionsService(directionsOptions, (directionsData, status) => {
        if (status === 'OK') {
          directionsDisplay.setMap(this.map)
          directionsDisplay.setDirections(directionsData)
          // GETS THE OVERVIEW PATH FOR SPOOFING
          // const overviewPath = directionsData.routes[0].overview_path
          // const test = overviewPath.map((path) => `${path.lat()} ${path.lng()}`)
          // for (const t of test) {
          // }
        }
      })
      this.directionsServiceRequestCounter++
    }
  }

  makeIcon(
    url,
    size
  ): {
    url: any
    scaledSize: any
  } {
    return { url, scaledSize: new this.google.maps.Size(...size) }
  }

  makeMarker(latitude, longitude, icon, optimized = true) {
    const marker = new this.google.maps.Marker({
      icon,
      position: new this.google.maps.LatLng(latitude, longitude),
    })
    if (!optimized) {
      marker.optimized = optimized
    }
    return marker
  }

  makeStopInfoWindow(waypoint, stopTitle, status) {
    const infoWindowObj = new this.google.maps.InfoWindow({
      content: this.makeStopInfoWindowContent(stopTitle, status),
    })
    infoWindowObj.id = `${waypoint.info.lat}-${waypoint.info.lng}`
    return infoWindowObj
  }

  makeStopInfoWindowContent(stopTitle, status) {
    return `<div> <b>${stopTitle}</b> <br>${status} </div>`
  }

  makeStopMarker(stop) {
    const waypoint = tracking.waypoints[stop.waypointIndex]
    const stopIconString = this.getStopIconString(stop)
    const stopIcon = this.makeIcon(stopIconString, [18, 18])
    const stopStatus = this.getStopStatus(stop)

    const stopTitle =
      waypoint.info.title ||
      `${waypoint.info.street1}, ${waypoint.info.city}, ${waypoint.info?.state}`

    const stopInfoWindow = this.makeStopInfoWindow(
      waypoint,
      stopTitle,
      stopStatus
    )

    const marker = this.makeMarker(stop.lat, stop.lng, stopIcon)
    marker.id = `${waypoint.info.lat}-${waypoint.info.lng}`
    this.stopMarkers.push(marker)

    this.stopInfoWindows.push(stopInfoWindow)

    marker.addListener('mouseover', () => stopInfoWindow.open(this.map, marker))
    marker.addListener('mouseout', () => stopInfoWindow.close())
    this.bounds.extend(marker.getPosition())
    marker.setMap(this.map)
  }

  makePickupStopMarker(stop: TrackingStop) {
    this.clearPickupStopMarkers()
    if (tracking.isComplete(stop)) {
      return
    }

    const zoomLevel = this.map.getZoom()

    const dimensions = [45, 20]
    const scalar = PICKUP_MARKER_OFFSETS[zoomLevel].scalar
    const scaledDimensions = dimensions.map((dim) => dim * scalar)

    const pickupIconString = this.createPickupIcon(scalar)
    const pickupIcon = this.makeIcon(pickupIconString, scaledDimensions)

    const marker = this.makeMarker(
      stop.address?.lat + PICKUP_MARKER_OFFSETS[zoomLevel].lat,
      stop.address?.lng + PICKUP_MARKER_OFFSETS[zoomLevel].lng,
      pickupIcon
    )
    marker.id = 'pickup-stop-icon'
    marker.setMap(this.map)
    this.pickupStopMarkers.push(marker)
  }

  getStopIconString(stop) {
    let stopIconType = LiveTrackingStatus.Upcoming

    const isIncompleteRoundTripStop =
      !stop.isNextStop && stop.orderIndex !== stop.waypointIndex
    if (tracking.isFinished || stop.isComplete || isIncompleteRoundTripStop) {
      stopIconType = LiveTrackingStatus.InProgress
    }

    return this.createStopIcon(stopIconType)
  }

  getStopStatus(stop) {
    let stopStatus = null
    if (tracking.isFinished || stop.isComplete) {
      stopStatus = this.$t('liveTracking.itinerary.COMPLETED')
    } else if (this.isInProgress && stop.isNextStop) {
      stopStatus = this.$t('liveTracking.itinerary.NEXT_STOP')
    } else {
      stopStatus = this.$t('liveTracking.itinerary.SCHEDULED')
    }
    const stopTimeFormatted = formatDisplayTime(stop.stopTime, stop.timeZone)
    return `${stopStatus} - ${stopTimeFormatted}`
  }

  createStopIcon(iconType): string {
    const propsData = {
      color: this.activeVehicleColor,
      iconType,
    }
    let component = new LiveTrackingMapStopIconClass({ propsData })
    component.$mount()
    return 'data:image/svg+xml,' + encodeURIComponent(component.$el.outerHTML)
  }

  createPickupIcon(scalar: number): string {
    const propsData = {
      color: this.activeVehicleColor || DEFAULT_MAP_THEME_COLOR,
      scalar,
    }
    let component = new LiveTrackingPickupMarkerClass({ propsData })
    component.$mount()
    return 'data:image/svg+xml,' + encodeURIComponent(component.$el.outerHTML)
  }

  updateStops() {
    for (const stop of this.stopsToDisplay) {
      const marker = this.stopMarkers.find(
        (stopMarker) => stopMarker.id === stop.id
      )
      const infoWindow = this.stopInfoWindows.find(
        (infoWindow) => infoWindow.id === stop.id
      )
      if (stop && marker) {
        this.updateStop(stop, marker, infoWindow)
      } else {
        console.warn('could not find stop to update')
      }
    }
  }

  updateStop(stop, marker, infoWindow) {
    const waypoint = tracking.waypoints[stop.waypointIndex]
    const stopIconString = this.getStopIconString(stop)
    const stopStatus = this.getStopStatus(stop)
    const stopIcon = this.makeIcon(stopIconString, [18, 18])
    marker.setIcon(stopIcon)
    const stopTitle =
      waypoint.info.title ||
      `${waypoint.info.street1}, ${waypoint.info.city}, ${waypoint.info?.state}`
    infoWindow.setContent(this.makeStopInfoWindowContent(stopTitle, stopStatus))
  }

  async updateMap(): Promise<void> {
    // don't redraw the route, just refresh stops
    await this.trackingVehicleStore.processVehicles()
    this.clearStopMarkers()
    this.setupStopsToDisplay()
    await this.drawRouteStopMarkers()
    this.updateStops()
    if (this.centeringMode !== 'custom') {
      await this.centerMap()
    }
  }

  onServiceRequestHandler(event): void {
    if (!this.directionsService) {
      this.initializeDirectionsService()
    }
    this.directionsService(event.options, event.callback)
    this.directionsServiceRequestCounter += 1
  }

  extendBounds(locations: Location[]): void {
    for (const location of locations) {
      const validLocation = location?.lat !== 0 && location?.lng !== 0
      if (validLocation) {
        this.bounds.extend(location)
      }
    }
  }

  convertToMinutes(timestamp: string): number {
    return DateTime.fromISO(timestamp).diffNow('minutes').minutes
  }
}
