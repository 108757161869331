
import { Vue, Component, Prop, Watch, Provide } from 'vue-property-decorator'
import BidCard from '@/components/BidCard.vue'
import ShareQuoteDialog from '@/components/ShareQuoteDialog.vue'
import QuoteDetailBidListExpirationWarning from '@/components/QuoteDetailBidListExpirationWarning.vue'
import QuoteDetailBidListUnavailableBids from '@/components/QuoteDetailBidListUnavailableBids.vue'
import QuoteDetailBidListCharterUpChoice from '@/components/QuoteDetailBidListCharterUpChoice.vue'
import CharterUPValueProps from '@/components/CharterUPValueProps.vue'
import {
  datetimeToShortLocalizedDateTime,
  datetimeToMilliseconds,
} from '@/utils/datetime'
import { Page, ValuePropKey } from '../utils/enum'
import ga4 from '@/store/modules/ga4'
import { PartnerTypeId, ViewQuoteSource } from '@/utils/enum'
import { QuoteDetailV2, QuoteDetailV2Bid } from '../models/dto/QuoteDetailV2'

@Component({
  components: {
    BidCard,
    ShareQuoteDialog,
    QuoteDetailBidListExpirationWarning,
    QuoteDetailBidListUnavailableBids,
    QuoteDetailBidListCharterUpChoice,
    CharterUPValueProps,
  },
})
export default class QuoteDetailBidList extends Vue {
  @Provide('isInCarousel') isInCarousel = false
  @Prop({ type: Object, default: null }) readonly quote: QuoteDetailV2
  @Prop({ type: Object, default: null }) readonly featuredBid: QuoteDetailV2Bid
  @Prop({ type: Boolean }) readonly showShareQuoteDialog: boolean
  @Prop({ type: Array }) readonly valuePropKeys: ValuePropKey[]

  page = Page.QuoteDetail

  get isPickupInCanada(): boolean {
    return this.quote?.trips?.[0]?.stops?.[0]?.address?.country === 'CA'
  }

  get bids(): QuoteDetailV2Bid[] {
    return this.quote?.bids
  }

  get bestBids(): QuoteDetailV2Bid[] {
    return this.quote?.bids
      ?.filter((bid) => {
        return bid.partnerTypeId !== PartnerTypeId.Bronze
      })
      ?.filter((bid) => {
        // We only want to filter the CharterUp Choice option out if we are going
        // to display it separately
        return (!bid.isCharterUPChoice)
      })
      .sort((a, b) => a.totalAmount - b.totalAmount)
      .sort((a, b) => Number(a.soldOut) - Number(b.soldOut))
  }

  get bronzeBids(): QuoteDetailV2Bid[] {
    return this.quote?.bids?.filter((bid) => {
      return bid.partnerTypeId === PartnerTypeId.Bronze
    })
  }

  get charterUPChoiceBid(): QuoteDetailV2Bid {
    return this.quote?.bids?.find((bid) => bid.isCharterUPChoice)
  }

  get bidsExpirationDate(): string {
    return datetimeToShortLocalizedDateTime(this.quote?.expirationDate)
  }

  get bidsExist(): boolean {
    return (
      (this.quote?.bids && this.quote?.bids.length !== 0) || !!this.featuredBid
    )
  }

  // Show bid expiration stamp if there bids that are not all bronze bids and we have a timestamp
  get showBidsExpiration(): boolean {
    return (
      this.bidsExist &&
      this.quote?.bids.length !== this.bronzeBids?.length &&
      !!this.bidsExpirationDate
    )
  }

  get isElite(): boolean {
    return !!this.quote?.isElite
  }

  get quoteId(): number {
    return this.quote?.quoteId
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown
  }

  mounted() {
    this.trackViewQuote()
  }

  trackViewQuote(): void {
    const source = ga4.viewQuoteSource || ViewQuoteSource.Other
    const createdOn = datetimeToMilliseconds(this.quote?.createdOn)
    this.$ga4Event('view_quote', {
      source: source.toString(),
      createdOn,
      isElite: this.isElite,
      isSelfServe: this.quote?.isSelfServe,
    })
  }
}
