/* eslint-disable no-redeclare */
import Vue from 'vue'
import Router, {
  isNavigationFailure,
  NavigationFailureType,
  RawLocation,
  Route,
} from 'vue-router'
import VueMeta from 'vue-meta'

import { routes } from './routes'
import auth from '@/services/auth'
import modules from '@/store/modules'
import systemParameter from '@/store/modules/systemParameter'
import { CustomerValidatePayload } from '@/models/dto'
import review from '@/services/review'
import { isIncompleteCustomer } from '@/utils/customer'
import { isEmailSourceInQueryParams } from '@/utils/url'
import { SplitKeyType, SplitAttribute, ViewQuoteSource } from '@/utils/enum'

Vue.use(Router)
Vue.use(VueMeta)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    const onComplete = () => resolve(this.currentRoute)
    const onAbort = (error) => {
      const isDuplicate = isNavigationFailure(
        error,
        NavigationFailureType.duplicated
      )
      const isRedirected = isNavigationFailure(
        error,
        NavigationFailureType.redirected
      )
      const isCancelled = isNavigationFailure(
        error,
        NavigationFailureType.cancelled
      )
      if (isDuplicate || isRedirected || isCancelled) {
        console.warn(error)
        resolve(this.currentRoute)
      } else {
        reject(error)
      }
    }
    originalPush.call(this, location, onComplete, onAbort)
  })
}

const router = new Router({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  if (!modules.auth.isTokenSet) {
    await modules.split.updateKey(SplitKeyType.AnonymousUser)
  }

  if (to.matched[0]?.name === 'guest') {
    // No need to worry about authentication.
    //
    // If the guest needs to have cookies set or accessed,
    //   that would be done here.
    return next()
  }

  if (to.path.startsWith('/tripreview/')) {
    const hash = to.path.replace('/tripreview/', '')
    try {
      const reviewResponse = await review.byHash(hash)
      const reservationId =
        reviewResponse.data.reservationReviewDetails.reservationId
      if (reservationId) {
        return next({
          name: 'reservation-detail',
          params: { id: reservationId.toString() },
        })
      }
    } catch (err) {
      console.warn(err)
    }
  }

  if (to.query.quoteHash) {
    const urlParams = new URLSearchParams(to.fullPath)
    const isSourceEmail = isEmailSourceInQueryParams(urlParams)
    if (isSourceEmail) {
      modules.ga4.setViewQuoteSource(ViewQuoteSource.Email)
    }

    const paramsToCheck = [
      'isWithin24Hours',
      'isLongTermShuttle',
      'isLargeEvent',
      'isBillAfterServicesAndWithin4Days',
    ]
    const query = paramsToCheck.reduce((acc, param) => {
      if (urlParams.has(param) && urlParams.get(param) === 'true') {
        acc[param] = 'true'
      }
      return acc
    }, {})

    if (Object.keys(query).length > 0) {
      return next({
        name: 'quote-index',
        query,
      })
    }
  }

  // If JWT is in params, use it to login.
  if (to.query.jwt) {
    await modules.auth.jwtLogin(to.query.jwt as string)
  }

  const isTokenSet = modules.auth.isTokenSet
  if (isTokenSet) {
    const isUserIdMissing = !modules.auth?.userId
    if (isUserIdMissing) {
      modules.auth.setUserIdFromUser()
    }

    await Promise.all([
      systemParameter.fetchSystemParameters(),
      modules.auth.refreshUser(),
      modules.auth.refreshCustomer(),
      modules.auth.refreshRolesAndPermissions(),
    ])

    await modules.split.updateKey(modules.auth.userId.toString())

    await modules.quotes.fetchUnreadQuoteCount()
  }

  const toReservationByHash =
    to.name === 'reservation-index' && to.query.reservationHash
  const toQuoteByHash = to.name === 'quote-index' && to.query.quoteHash
  if (toReservationByHash || toQuoteByHash) {
    const entityType = toQuoteByHash ? 'quote' : 'reservation'
    const entityHash = to.query[`${entityType}Hash`]
    const fromSignup = to.query.fromSignup
    const receiveSMS = to.query.receiveSMS

    if (isTokenSet) {
      const customer = modules?.auth?.customer
      if (isIncompleteCustomer(customer)) {
        return next({
          name: 'signup',
          params: {
            firstName: customer?.firstName,
            lastName: customer?.lastName,
            email: customer?.email,
            phone: customer?.phone,
          },
          query: {
            redirectFrom: to.fullPath,
            reservationHash: entityHash,
            isCustomerInvite: 'true',
            isIncompleteCustomer: 'true',
            entityType,
          },
        })
      }
      const toDetail = {
        name: `${entityType}-detail-by-hash`,
        params: {
          hash: entityHash as string,
        },
        query: {},
      }
      if (fromSignup) {
        toDetail.query['fromSignup'] = fromSignup
      }
      if (receiveSMS) {
        toDetail.query['receiveSMS'] = receiveSMS
      }
      return next(toDetail)
    } else {
      const payload: CustomerValidatePayload = {
        reservationHash: entityHash as string,
        userHash: (to.query.userHash as string) || '',
        verifyCustomerDetails: true,
      }
      try {
        const customerValidateResult = await auth.validateCustomer(payload)
        const userCurrentlySigningUp = !!customerValidateResult?.data?.email
        if (userCurrentlySigningUp) {
          return next({
            name: 'signup',
            params: {
              firstName: customerValidateResult.data.firstName,
              email: customerValidateResult.data.email,
              hash: customerValidateResult.data.hash,
            },
            query: {
              redirectFrom: to.fullPath,
              reservationHash: entityHash,
              userHash: to.query.userHash || customerValidateResult.data.hash,
              isCustomerInvite: to.query.isCustomerInvite || null,
              entityType,
            },
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  if (requiresAuth && !isTokenSet) {
    return next({
      name: 'login',
      query: {
        redirectFrom: to.fullPath,
      },
    })
  }

  const goingToQuoteConfirm =
    to.name === 'quote-detail' && to.params?.mode === 'confirm'
  const redirectWithinSameQuote =
    to.name === 'quote-detail' &&
    from.name === 'quote-detail' &&
    `${to.params?.id}` === `${from.params?.id}`
  if (redirectWithinSameQuote) {
    modules.footer.setShow(false)
  } else if (!goingToQuoteConfirm) {
    modules.footer.setShow(true)
  }

  return next()
})

export default router
