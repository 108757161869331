import { AxiosResponse } from 'axios'
import { baseUrl } from '@/utils/env'
import {
  CreateReservationReviewResult,
  ReservationReview,
  ReservationReviewDetailsResult,
} from '@/models/dto'
import { HttpService } from './common/HttpService'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Gets a reservation review by the given hash.
   *
   * @param hash - The hash to use to get the reservation review.
   * @returns A promise that resolves to the result containing the reservation review.
   */
  byHash(hash): Promise<AxiosResponse<ReservationReviewDetailsResult>> {
    const host = baseUrl()
    const url = `https://${host}/reservations/v2/reviews/${hash}`
    return httpService.get(url)
  },
  /**
   * Creates a reservation review with the given payload.
   *
   * @param payload - The payload containing the information for the reservation review.
   * @returns A promise that resolves to the result of creating the reservation review.
   */
  create(
    payload: ReservationReview
  ): Promise<AxiosResponse<CreateReservationReviewResult>> {
    const host = baseUrl()
    const url = `https://${host}/reservations/v2/reviews`
    return httpService.post<CreateReservationReviewResult, ReservationReview>(
      url,
      payload
    )
  },
}
