import { AmenityItem } from '@/models/AmenityItem'
import { AmenityType } from '@/models/dto'
import { AmenityTypeId, VehicleTypeId } from './enum'
import deepClone from './deepClone'

const AVAILABLE_AMENITIES = [
  {
    id: AmenityTypeId.Bathroom,
    icon: 'bathroom',
    title: 'Bathroom',
    color: 'primary',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  {
    id: AmenityTypeId.Luggage,
    icon: 'luggage',
    title: 'Luggage Storage',
    color: 'primary',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  {
    id: AmenityTypeId.Outlets,
    icon: '',
    title: 'Outlets',
    color: 'primary',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  {
    id: AmenityTypeId.TVScreens,
    icon: '',
    title: 'TV Screens',
    color: 'primary',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  {
    id: AmenityTypeId.Wifi,
    icon: 'wifi',
    title: 'Wifi',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  {
    id: AmenityTypeId.LeatherSeats,
    icon: 'airline_seat_recline_extra',
    title: 'Leather Seats',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  {
    id: AmenityTypeId.AlcoholConsumption,
    icon: 'liquor',
    title: 'Alcohol Allowed',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
]

const getAvailableAmenitiesByVehicleTypeId = (
  amenities: AmenityType[],
  vehicleTypeId: number
): AmenityItem[] => {
  if (!amenities || !vehicleTypeId) {
    return []
  }
  return deepClone(AVAILABLE_AMENITIES).filter(
    (AMENITY) =>
      AMENITY.vehicleTypeIds.includes(vehicleTypeId) &&
      amenities.some((amenity) => amenity.id === AMENITY.id)
  )
}

export const getAvailableAmenityTypesByVehicleTypeId = (
  amenities: AmenityType[],
  vehicleTypeId: number
): AmenityType[] => {
  if (!amenities || !vehicleTypeId) {
    return []
  }
  const availableAmenityTypeIds = getAvailableAmenitiesByVehicleTypeId(
    amenities,
    vehicleTypeId
  ).map((AMENITY) => AMENITY.id)
  return amenities.filter((amenity) =>
    availableAmenityTypeIds.includes(amenity.id)
  )
}

export const getCharterBusAmenities = (
  amenities: AmenityType[]
): AmenityItem[] => {
  return getAvailableAmenitiesByVehicleTypeId(
    amenities,
    VehicleTypeId.CharterBus
  )
}

export const getMiniBusAmenities = (
  amenities: AmenityType[]
): AmenityItem[] => {
  return getAvailableAmenitiesByVehicleTypeId(amenities, VehicleTypeId.MiniBus)
}

export const getAmenityIcon = (amenityTypeId: number): string => {
  const amenityItem = AVAILABLE_AMENITIES.find(
    (AMENITY) => AMENITY.id === amenityTypeId
  )
  return amenityItem ? amenityItem.icon : ''
}
