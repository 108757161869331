
import { Component, Vue, Prop, Inject } from 'vue-property-decorator'
import CStarRating from '@/components/CStarRating.vue'
import { alwaysShowTenthsPlace } from '@/utils/string'
import { QuoteDetailV2Bid } from '@/models/dto'

@Component({ components: { CStarRating } })
export default class BidCardRatingAndReviews extends Vue {
  @Prop({ required: true, type: Object }) bid!: QuoteDetailV2Bid
  @Prop({ required: true, type: Boolean }) stacked!: boolean

  alwaysShowTenthsPlace = alwaysShowTenthsPlace

  get rating(): number {
    return this.bid.rating
  }

  get reviewCount(): number {
    return this.bid.reviewCount
  }

}
