
import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import CustomerInviteSearch from '@/components/CustomerInviteSearch.vue'
import CustomerInviteForm from '@/components/CustomerInviteForm.vue'
import quotes from '@/services/quotes'
import { Customer, InviteCustomerPayload } from '@/models/dto'
import { getInitials, validateEmailAddress } from '@/utils/string'
import customer from '@/services/customer'
import { Page } from '@/utils/enum'

@Component({ components: { CustomerInviteSearch, CustomerInviteForm } })
export default class ShareQuoteDialog extends Vue {
  @Inject({ from: 'isInQuoteDetail', default: false })
  readonly isInQuoteDetail: boolean
  @Inject({ from: 'isInProvider', default: false })
  readonly isInProvider: boolean
  @Inject({ from: 'isInCheckout', default: false })
  readonly isInCheckout: boolean
  @Inject({ from: 'isInGuestCheckout', default: false })
  readonly isInGuestCheckout: boolean
  @Prop({ type: Number, required: true }) readonly quoteId: number
  @Prop({ type: String, default: '' }) readonly buttonClass: string
  @Prop({ type: Boolean }) readonly text: boolean

  value = false
  showInviteForm = false
  sharedList = []
  submitting = false
  inviteEmail = ''
  getInitials = getInitials
  validateEmailAddress = validateEmailAddress
  invitingEmailSet = new Set()
  inviteErrorMessages = []

  mounted(): void {
    this.getSharedList()
  }

  get page(): string {
    if (this.isInQuoteDetail) {
      return Page.QuoteDetail
    }
    if (this.isInProvider) {
      return Page.Provider
    }
    if (this.isInCheckout || this.isInGuestCheckout) {
      return Page.Checkout
    }
    return Page.Other
  }

  async getSharedList(): Promise<void> {
    const sharedListResponse = await quotes.sharedList(this.quoteId)
    this.sharedList = sharedListResponse.data.sharedList || []
  }

  async share(customer: Customer): Promise<void> {
    await quotes.share(this.quoteId, customer.customerId)
    this.getSharedList()
  }

  async unshare(customer: Customer): Promise<void> {
    await quotes.unshare(this.quoteId, customer.customerId)
    this.getSharedList()
  }

  async handleInviteNewUser(email: string): Promise<void> {
    if (this.invitingEmailSet.has(email)) {
      return
    }

    this.invitingEmailSet.add(email)
    if (validateEmailAddress(email)) {
      const customer: InviteCustomerPayload = {
        firstName: '',
        lastName: '',
        phone: '',
        email,
      }
      await this.handleSubmit(customer)
      this.invitingEmailSet.delete(email)
      return
    }

    this.showForm(email)
    this.invitingEmailSet.delete(email)
  }

  showForm(email: string): void {
    this.showInviteForm = true
    this.inviteEmail = email
  }

  close(): void {
    this.value = false
  }

  validateEmail(email: string): boolean {
    this.inviteErrorMessages = []
    const valid = validateEmailAddress(email)
    if (!valid) {
      this.inviteErrorMessages.push('Please enter a valid email.')
      return false
    }
    return true
  }

  startSubmit(): void {
    const inviteSearch = this.$refs['customerInviteSearch'] as any
    const inviteEmail = inviteSearch.search

    this.submitting = true
    this.handleSubmit({
      email: inviteEmail,
      firstName: '',
      lastName: '',
      phone: '',
    })
  }

  async handleSubmit(payload: InviteCustomerPayload): Promise<void> {
    const valid = this.validateEmail(payload.email)
    if (!valid) {
      this.submitting = false
      return
    }

    try {
      await customer.inviteByQuoteId(this.quoteId, payload)
      this.trackShareQuote()
      await this.getSharedList()
    } catch (error) {
      console.error(error)
    }
    this.showInviteForm = false
    this.submitting = false
  }

  trackShareQuote(): void {
    this.$ga4Event('share_quote', {
      page: this.page,
    })
  }
}
