var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex padding-t-4",class:{
    'justify-space-between margin-b-4 align-center': !_vm.isSingleColumn,
    'flex-column margin-b-4 ': _vm.isSingleColumn,
  }},[_c('h1',{staticClass:"font-28",class:{
      'margin-b-2': _vm.isSingleColumn,
      'text-center': _vm.fullWidth,
      'margin-b-0': !_vm.fullWidth,
    }},[_vm._v(" "+_vm._s(_vm.headerText)+" ")]),(!_vm.isInGuestCheckout)?_c('div',{staticClass:"d-flex align-center",class:{ 'justify-end': !_vm.fullWidth, 'justify-center': _vm.fullWidth }},[_c('v-btn',{staticClass:"font-book font-weight-600 padding-x-4 margin-r-3",class:{
        'font-14': _vm.$vuetify.breakpoint.lgAndDown,
        'font-16': _vm.$vuetify.breakpoint.xlOnly,
      },attrs:{"id":"quote-detail-button-contact-support","text":"","color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openSupport.apply(null, arguments)}}},[_c('CUIcon',{staticClass:"margin-r-2 w-16 h-16",attrs:{"view-box":"0 0 24 24"}},[_vm._v(" support_agent ")]),_vm._v(" "+_vm._s(_vm.$t('common.CONTACT_SUPPORT'))+" ")],1),(_vm.showShareQuoteDialog)?_c('ShareQuoteDialog',{attrs:{"quote-id":_vm.quoteId},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"border-radius-8",class:{
            'padding-a-2 h-32 max-h-32': _vm.$vuetify.breakpoint.lgAndDown,
            'padding-x-3 padding-y-4': _vm.$vuetify.breakpoint.xlOnly,
          },attrs:{"small":"","outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"d-flex align-center"},[_c('CUIcon',{staticClass:"w-16 h-16 margin-r-2",attrs:{"view-box":"0 0 24 24"}},[_vm._v(" share_arrow ")]),_vm._v(" "+_vm._s(_vm.$t('common.SHARE'))+" ")],1)])]}}],null,false,973151294)}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }