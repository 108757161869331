
import { Vue, Component } from 'vue-property-decorator'
import TripModificationReview from '@/components/TripModificationReview.vue'
import tripModificationStore from '@/store/modules/tripModification'
import authStore from '@/store/modules/auth'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'

@Component({
  components: {
    TripModificationReview,
  },
})
export default class TripModificationConfirmation extends Vue {

  get hasTripModificationId(): boolean {
    return !!tripModificationStore.tripModificationId
  }

  get subHeader(): string {
    const managedId = tripModificationStore.managedId
    const routeName = tripModificationStore.routeName
    const formattedId = `ID ${managedId}`
    return routeName ? `${routeName} – ${formattedId}` : formattedId
  }

  get customerEmail(): string {
    return authStore.customer.email
  }

  get supportNumber(): string {
    return getSupportNumber(authStore.customer.isElite)
  }

  get formattedSupportNumber(): string {
    return `1-${phoneFormatFilterHyphens(this.supportNumber)}`
  }
}
