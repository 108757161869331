import { Action, Module, VuexModule } from 'vuex-class-modules'
import axios from 'axios'

import auth from '@/services/auth'
import store from '@/store/index'
import { clear, load, save } from '@/utils/localStorage'
import { findPermissionByName } from '@/utils/permissions'
import customerAccount from '@/services/customerAccount'
import router from '@/router'
import { AuthPayload, AuthUser, Customer, CustomerAccount, Role, User } from '@/models/dto'
import customer from '@/services/customer'
import user from '@/services/user'
import support from '@/store/modules/support'
import * as datadog from '../../utils/datadog'

const CMS_ADMIN = 'canViewOperatorDetailPreview'
const ENTERPRISE_CLIENT = 'canViewEnterpriseTracking'
const ENTERPRISE_ADMIN = 'canViewEnterpriseTrackingReservationDetails'
const FINANCE_ADMIN = 'canViewCharterUPCheckoutMeta'
const CUSTOMER_ACCOUNT_ADMIN = 'canViewCustomerAccountAdmin'
const DEFAULT_CUSTOMER_SUPPORT_NUMBER = '8559202287'

@Module({ generateMutationSetters: true })
class AuthModule extends VuexModule {
  _user: User = load('user') || null
  _customer: Customer = load('customer') || null
  _token: string = load('token') || null
  _isTokenSet = !!localStorage.getItem('token')
  _roles: Role[] = []
  _userId: number = load('userId') || null
  _customerAccount: CustomerAccount = load('customerAccount') || null
  _customerAccountId: number = null
  _childCustomerAccountIds: number[] = []

  /**
   * @returns the authenticated user.
   */
  get user(): User {
    return this._user
  }

   /**
     * This is used when a new user is completing the self serve flow.
     * @returns the default customer support number.
   */
  get defaultCustomerSupportNumber(): string {
    return DEFAULT_CUSTOMER_SUPPORT_NUMBER
  }

  /**
   * @returns the authenticated customer.
   */
  get customer(): Customer {
    return this._customer
  }

  /**
   * @returns the customer account associated with the authenticated user.
   */
  get customerAccount(): CustomerAccount {
    return this._customerAccount
  }

  /**
   * @returns the customer sub-accounts associated with the authenticated user.
   * These are the child accounts of the authenticated user's customer account.
   * If the authenticated user is not associated with a customer account, this will return an empty array.
   */
  get customerSubAccounts(): CustomerAccount[] {
    return this._customerAccount?.teams || []
  }

  /**
   * @returns the customer account ID associated with the authenticated user.
   */
  get customerAccountId(): number {
    return this._customerAccountId
  }

  /**
   * @returns the token for the authenticated user.
   */
  get token(): string {
    return this._token
  }

  /**
   * @returns whether the token is set for the authenticated user.
   */
  get isTokenSet(): boolean {
    return this._isTokenSet
  }

  /**
   * @returns the roles associated with the authenticated user.
   */
  get roles(): Role[] {
    return this._roles
  }

  /**
   * @returns whether the authenticated user is a CMS admin.
   */
  get isCMSAdmin(): boolean {
    return findPermissionByName(this._roles, CMS_ADMIN)
  }

  /**
   * @returns whether the authenticated user is an enterprise client.
   */
  get isEnterpriseClient(): boolean {
    return findPermissionByName(this._roles, ENTERPRISE_CLIENT)
  }

  /**
   * @returns whether the authenticated user is an enterprise admin.
   */
  get isEnterpriseAdmin(): boolean {
    return findPermissionByName(this._roles, ENTERPRISE_ADMIN)
  }

  /** @returns wether or not the user is an organization admin */
  get isCustomerAccountAdmin(): boolean {
    return findPermissionByName(this._roles, CUSTOMER_ACCOUNT_ADMIN)
  }

  /**
   * @returns whether the authenticated user is a finance admin.
   */
  get isFinanceAdmin(): boolean {
    return findPermissionByName(this._roles, FINANCE_ADMIN)
  }

  /**
   * @returns the current user's user ID.
   */
  get userId(): number {
    return this._userId
  }

  /**
   * @returns the user's child customer account IDs.
   */
  get childCustomerAccountIds(): number[] {
    return this._childCustomerAccountIds
  }

  /**
   * Login the user with the given payload.
   * @param payload - The payload containing the user's login credentials.
   */
  @Action
  async login(payload: AuthPayload): Promise<void> {
    try {
      const response = await auth.login(payload)
      if (response.data.successful) {
        const result = response.data
        save('userId', result.user.id)
        save('token', result.token)
        save('customerAccountId', result.user.customerAccountId)
        this._userId = result.user.id
        this._customerAccountId = result.user.customerAccountId
        this._token = result.token
        this._isTokenSet = !!result.token
        registerBearerToken(response.data.token)

        await this.refreshUser()
        setDataDogUserContext(this._user)
      }
    } catch (error) {
      console.warn(error)
    }
  }

  /**
   * Login the user with the given payload.
   * @param token - The JWT to authenticate the user.
   */
  @Action
  async jwtLogin(token): Promise<void> {
    try {
      const response = await auth.jwtLogin({ token })
      if (response.data.successful) {
        const result = response.data
        save('userId', result.user.id)
        save('token', result.token)
        save('customerAccountId', result.user.customerAccountId)
        this._userId = result.user.id
        this._customerAccountId = result.user.customerAccountId
        this._token = result.token
        this._isTokenSet = !!result.token
        registerBearerToken(response.data.token)

        await this.refreshUser()
        setDataDogUserContext(this._user)
      }
    } catch (error) {
      console.warn(error)
    }
  }

  /**
   * Attempts to automatically log in by loading the saved user data from local storage.
   */
  @Action
  autoLogin(): void {
    this._user = load('user')
    this._userId = load('userId')
    this._token = load('token')
    this._roles = load('roles')
    this._customerAccountId = load('customerAccountId')

    setDataDogUserContext(this._user)
  }

  /**
   * Refreshes the user data using the saved user ID.
   */
  @Action
  async refreshUser(): Promise<void> {
    if (!this._userId) {
      return
    }

    const response = await user.byId(this._userId)
    this._user = response.data.user

    save('user', response.data.user)
  }

  /**
   * Refreshes the user data using the saved user ID.
   */
  @Action
  async refreshCustomer(): Promise<void> {
    if (!this._userId) {
      return
    }

    const response = await customer.byId(this._userId)
    this._customer = response.data.customer
    save('customer', response.data.customer)
  }

  /**
   * Loads the user from local storage into the state.
   * @returns the user.
   */
  @Action
  getUserFromLocalStorage(): User {
    const user = load('user') || null
    this._user = user
    return user
  }

  /**
   * Refreshes the users roles and permissions from the database.
   */
  @Action
  async refreshRolesAndPermissions() {
    const response = await auth.getUserRolesAndPermissions()
    save('roles', response.data.userProfile.roles)
    this._roles = response.data.userProfile.roles
  }

  /**
   * Refreshes the customer account information by fetching the latest data from the database and storing it in the state.
   */
  @Action
  async refreshCustomerAccount(): Promise<void> {
    try {
      const response = await customerAccount.get()
      const customerAccountResponse = response.data
      save('customerAccount', customerAccountResponse)
      save('customerAccountId', customerAccountResponse.customerAccountId)
      let childCustomerAccountIds: number[] = []
      if (customerAccountResponse.teams) {
        childCustomerAccountIds = customerAccountResponse.teams.map(
          (team) => team.customerAccountId
        )
      }
      save('childCustomerAccountIds', childCustomerAccountIds)
      this._childCustomerAccountIds = childCustomerAccountIds
      this._customerAccount = customerAccountResponse
    } catch (error) {
      console.warn('Could not refresh customer account')
      console.warn(error)
    }
  }

  /**
   * Loads the user from local storage into the state.
   * @returns the user.
   */
  @Action
  getCustomerAccountFromLocalStorage(): CustomerAccount {
    const customerAccount = load('customerAccount') || null
    this._customerAccount = customerAccount
    return customerAccount
  }


  /**
   * Logs out the user and clears the state.
   */
  @Action
  logout(): void {
    clear()
    // keep other stores synced with local storage
    support.clear()
    this._user = null
    this._userId = null
    this._customerAccountId = null
    this._token = null
    this._isTokenSet = false
    this._roles = []

    datadog.clearUserContext()

    router.push({
      name: 'login',
    })
  }

  /**
   * Registers the token with the `registerBearerToken` function.
   * If a token exists in local storage, it will be used. Otherwise, no action will be taken.
   */
  @Action
  registerToken(): void {
    const token = load('token')
    if (token) {
      registerBearerToken(token)
    }
  }

  /**
   * Sets the user id from the user stored in the state
   */
  @Action
  setUserIdFromUser(): void {
    this._userId = this._user.userId
  }
}

//private helpers
/**
 * Registers a bearer token for use with authenticated requests.
 * @param token - The bearer token to use for authentication.
 */
const registerBearerToken = (token: string): void => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

/**
 * Sets the DataDog context to a given user.
 * @param user - The User whose userId, email, and name will be set in the DataDog context.
 */
const setDataDogUserContext = (user: User | null): void => { // add null here since we are effectively checking for that
  if (!user) {
    return
  }
  const { firstName, lastName, userId, email } = user
  const fullName = [firstName, lastName].filter(Boolean).join(" ")
  datadog.setUserContext(userId.toString(), email, fullName)
};

export default new AuthModule({ store, name: 'auth' })
