var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isMobile)?_c('CharterUPValueProps',{staticClass:"margin-b-3",attrs:{"value-prop-keys":_vm.valuePropKeys,"page":_vm.page}}):_vm._e(),(_vm.showBidsExpiration)?_c('QuoteDetailBidListExpirationWarning',{staticClass:"margin-b-4",class:{ 'margin-b-10': _vm.$vuetify.breakpoint.mdAndDown },attrs:{"quote":_vm.quote}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown && _vm.showShareQuoteDialog)?_c('div',{staticClass:"d-flex align-end margin-t-n6 margin-b-3",class:{
      'justify-space-between': _vm.showBidsExpiration,
      'justify-end': !_vm.showBidsExpiration,
    }},[_c('ShareQuoteDialog',{attrs:{"quote-id":_vm.quote.quoteId}})],1):_vm._e(),(_vm.charterUPChoiceBid)?_c('QuoteDetailBidListCharterUpChoice',{attrs:{"bid":_vm.charterUPChoiceBid,"is-elite":_vm.isElite,"quote":_vm.quote}}):_vm._e(),(_vm.featuredBid && _vm.featuredBid.companyId)?_c('BidCard',{staticClass:"margin-b-3",attrs:{"id":"quote-detail-bidcard-featured","preferred":"","featured":"","go-to":{
      name: 'provider-detail',
      params: {
        quoteId: _vm.quoteId || null,
        providerId: _vm.featuredBid.companyId,
      },
    },"bid":_vm.featuredBid,"is-elite":_vm.isElite,"show-usd":_vm.isPickupInCanada}}):_vm._e(),(
      _vm.featuredBid && _vm.featuredBid.companyId && _vm.bestBids && _vm.bestBids.length > 0
    )?_c('div',{staticClass:"bid-header font-24"},[_vm._v(" "+_vm._s(_vm.$t('quoteDetail.bidList.OTHER_COMPANIES'))+" ")]):_vm._e(),_c('v-row',{staticClass:"padding-t-0"},[(_vm.bestBids)?_vm._l((_vm.bestBids),function(bid,bidIndex){return _c('v-col',{key:`${bid.companyId}-${bidIndex}`,attrs:{"cols":"12"}},[_c('BidCard',{attrs:{"id":`quote-detail-bidcard-${bidIndex}`,"preferred":"","go-to":{
            name: 'provider-detail',
            params: {
              quoteId: _vm.quote ? _vm.quote.quoteId : null,
              providerId: bid.companyId,
            },
          },"bid":bid,"is-elite":_vm.isElite,"show-usd":_vm.isPickupInCanada}})],1)}):_vm._e()],2),(_vm.bronzeBids && _vm.bronzeBids.length)?_c('QuoteDetailBidListUnavailableBids',{attrs:{"bids":_vm.bronzeBids,"is-elite":_vm.isElite,"quote-id":_vm.quote.quoteId}}):_vm._e(),(!_vm.bidsExist)?_c('div',{staticClass:"text-center mt-6"},[_vm._v(" "+_vm._s(_vm.$t('quoteDetail.bidList.NO_BIDS'))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }