
import { TableViewQuote, TableViewStop } from '@/models/dto'
import VehicleTypeIcon from '@/components/VehicleTypeIcon.vue'
import {
  currencyFilter,
  expirationDelta,
  formatPickupDestinationTextFromAddresses,
  pluralize,
} from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { willSalesCall, shouldUserCallIn } from '@/utils/quote'
import { TranslateResult } from 'vue-i18n'
import {
  QuoteDetailV2,
  QuoteDetailV2Bid,
  QuoteDetailV2Trip,
} from '../models/dto/QuoteDetailV2'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({ components: { VehicleTypeIcon, SkeletonBox } })
export default class QuoteIndexMobileCard extends Vue {
  @Prop({ type: Object, required: false, default: null }) row: TableViewQuote

  get quoteDetail(): QuoteDetailV2 {
    return this.row?.quoteDetail || null
  }

  get firstTrip(): QuoteDetailV2Trip {
    return this.quoteDetail?.trips?.[0] || null
  }

  get firstPickup(): TableViewStop {
    return (
      this.row?.trips?.[0]?.stops?.find((stop) => stop.orderIndex === 0) || null
    )
  }

  get firstDropoff(): TableViewStop {
    return (
      this.row?.trips?.[0]?.stops?.find((stop) => stop.orderIndex === 1) || null
    )
  }

  get isSelfServe(): boolean {
    return this.row?.isSelfServe
  }

  get isExpired(): boolean {
    if (!this.row.expirationDate) {
      return true
    }
    const now = new Date()
    const thenDate = new Date(`${this.row.expirationDate.split('+')[0]}Z`)
    return now.getTime() - thenDate.getTime() > 0
  }

  get routeName(): string {
    if (!this.firstPickup || !this.firstDropoff) {
      return ''
    }

    return (
      this.firstTrip?.routeName ||
      formatPickupDestinationTextFromAddresses(
        this.firstPickup.address,
        this.firstDropoff.address,
        '>'
      )
    )
  }

  get passengerCount(): number {
    return this.firstTrip?.passengerCount || null
  }

  get bid(): QuoteDetailV2Bid {
    if (!this.isSelfServe) {
      return null
    }
    return this.quoteDetail?.bids?.[0] || null
  }

  get price(): string | TranslateResult {
    if (this.bid) {
      return currencyFilter(this.bid?.totalAmount)
    }

    if (this.isExpired) {
      return this.$t('quotesTable.EXPIRED')
    }

    if (willSalesCall(this.quoteDetail) || shouldUserCallIn(this.quoteDetail)) {
      return this.$t('common.MORE_INFO')
    }

    return this.$t('quotesTable.VIEW_PRICES')
  }

  get expiration(): string | TranslateResult {
    const timestamp = this.row.expirationDate
    if (!timestamp) {
      return this.$t('common.INVALID_DATE')
    }
    if (this.isExpired) {
      return this.$t('quotesTable.EXPIRED')
    }

    return expirationDelta(timestamp)
  }

  get quoteId(): number {
    return this.row?.quoteId
  }

  get vehicles(): string[] {
    if (!this.firstTrip) {
      return []
    }
    return this.firstTrip.vehicles.map((vehicleType) => {
      const quantity = vehicleType.quantity
      const label = vehicleType.vehicleType.label
      return `${quantity} ${pluralize(quantity, label)}`
    })
  }

  get displayVehicleType(): string {
    if (this.isExpired) {
      return 'Charter Bus'
    }
    return this.firstTrip?.vehicles?.[0]?.vehicleType?.label || null
  }

  get showEdit(): boolean {
    // TEMPORARILY DISABLE - 8/14/23
    return false
    // if (this.isExpired) {
    //   return false
    // }
    // if (!this.isSelfServe) {
    //   return false
    // }
    // return true
  }
}
