
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AmenityItem } from '@/models/AmenityItem'
import TripAmenityCardItem from '@/components/TripAmenityCardItem.vue'
import { formatIncludedAmenities } from '@/utils/string'

@Component({
  components: {
    TripAmenityCardItem,
  },
})
export default class TripAmenityCard extends Vue {
  @Prop({ type: Boolean, default: false }) readonly charterBus!: boolean
  @Prop({ type: Boolean, default: false }) readonly inResDetail: boolean
  @Prop({ type: Array }) readonly amenities!: Array<AmenityItem>

  get addedAmenities(): any[] {
    return this.amenities.filter((amenity) => !amenity.included)
  }

  get areAddedAmenities(): boolean {
    return this.addedAmenities.length !== 0
  }

  get title(): string {
    if (this.charterBus) {
      return 'Charter Bus Amenities'
    }
    return 'Mini Bus Amenities'
  }

  get formattedIncludedAmenities(): string {
    return formatIncludedAmenities(this.amenities)
  }
}
