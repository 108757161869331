
import { QuoteDetailV2Bid } from '@/models/dto'
import { roundedCurrencyFilter } from '@/utils/string'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ name: 'BidCardPricing' })
export default class BidCardPricing extends Vue {
  @Prop({ type: Object, required: true }) bid!: QuoteDetailV2Bid
  @Prop({ type: Boolean, default: false }) stacked!: boolean
  @Prop({ type: Boolean, default: false }) isValidBid!: boolean
  @Prop({ type: Boolean, default: false }) charCountMoreThan7!: boolean
  @Prop({ type: Boolean, default: false }) showUsd!: boolean

  get awaitingBid(): boolean {
    return this.bid.awaitngBid
  }

  get isFeatured(): boolean {
    return this.bid.featured
  }

  get hasDiscount(): number {
    return this.bid?.discountPercent
  }

  get formattedTotalAmount(): string {
    const suffix = this.showUsd ? ' USD' : ''
    const amount = roundedCurrencyFilter(Math.ceil(this.bid.totalAmount))
    return `${amount}${suffix}`
  }

  get formattedSubtotalAmount(): string {
    return roundedCurrencyFilter(Math.ceil(this.bid.subtotalAmount))
  }
}


